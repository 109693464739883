.image_cropper_container_main {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    min-width: -webkit-fill-available;

    .image_cropper_main_row {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .image_cropper_main_col {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            background-color: white;
            padding: 20px;

            .cropper_buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button {
                    margin-left: 10px;
                }
            }


            .cropper_title {
                border: 1px solid $color-black-7;
                background-color: $color-white;
                // padding: 20px;
                margin-bottom: 20px;
                border-radius: $border-radius-card;
                height: 100px;
                position: relative;
                z-index: 0;
            }

            .image_cropper_container {
                border: 1px solid $color-black-7;
                height: 200px;
                position: relative;
                margin-bottom: 15px;
                border-radius: 10px
            }
        }

    }


}

.image_cropper_container_main .image_cropper_main_row .image_cropper_main_col .cropper_title input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

}

.image_cropper_container_main .image_cropper_main_row .image_cropper_main_col .cropper_title:after {
    content: "Upload";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    font-weight: bold;
    font-size: 20px;
}

.image_cropper_container_main .image_cropper_main_row .image_cropper_main_col .cropper_title:before {
    content: "\f603";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: -55px;
    font-family: "bootstrap-icons";
    font-size: 20px;
}

.image_cropper_tools_zoom,
.image_cropper_tools_rotation {
    .MuiSlider-root {
        color: $color-black;
    }
}