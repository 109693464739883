// .container{
//     flex-direction:column
// }
.login-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .user-verification-container {
        width: 350px;
        margin-top: 30px;
    }
}