.ship-add-container {

    // display: flex;
    .add-card {
        width: 100%;
        border-radius: 5px;
        border-color: $color-black-7;
        background-color: $color-white;
        overflow: hidden;
        padding: 0;

        .card-content {
            display: flex;
            flex-direction: column;
        }

        .edit_span {
            cursor: pointer;
            color: $color-black;
            font-weight: 600;
            font-size: $font-size-sml;
        }

        .ship-add-content {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 10px 15px;
            font-size: $font-size-sml;
            line-height: 22px;



            .ship-add-count {
                text-decoration: underline;
                font-style: italic;
            }
        }


    }


    .footerbtns {
        background-color: gray;
        padding: 10px;
    }
}

.resi_type {
    position: absolute;
    right: 0px;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: small;
    font-weight: bold;
    background-color: $color-offwhite;
}

.add-default {
    padding: 10px 15px;
    background: $color-black;
    position: relative;

    .resi_type {
        background-color: $color-white;
        top: 7px;
    }

    strong {
        color: $color-white;
    }

}

.ship-add-content {
    padding: 10px 15px;
}