.container {

    .thank-you-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 1280px;
        padding: 0px 100px 0px 100px;

        .title {

            h4 {
                margin-bottom: 40px;
                font-size: 24px;
                font-weight: 700;
                margin-top: 10px;
            }
        }

        .content {
            display: flex;
            margin-bottom: 40px;
            width: 100%;

            .thank-you {

                padding: 50px 20px 50px 20px;
                width: 50%;

                h1 {
                    font-weight: 700;
                }

                .description {
                    font-size: 14px;
                    margin-bottom: 30px;
                }

                .billing-address {

                    margin-bottom: 50px;

                    h5 {
                        margin-bottom: 25px;
                        font-weight: 700;
                    }

                    .address {
                        display: flex;

                        .fields {

                            .fields-div {

                                display: flex;

                                .field {
                                    font-weight: 700;
                                    font-size: 14px;
                                    width: 130px;
                                    display: flex;
                                }

                                .value {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .summary {
            background-color: #f5f2f2;
            padding: 50px 20px 50px 20px;
            border-radius: 10px;
            width: 50%;

            h4 {
                font-weight: 700;
                margin-bottom: 20px;
            }

            .order-statics {
                display: flex;
                border-top: 1px solid #e2e2e2;
                border-bottom: 1px dotted #e2e2e2;
                padding: 10px 0px 10px 0px;


                .field {
                    color: silver;
                    font-size: 14px;
                    margin-bottom: 0px;
                }

                .value {
                    font-weight: 700;
                    font-size: 14px;
                }

                .order-number {
                    border-left: 1px solid #e2e2e2;
                    border-right: 1px solid #e2e2e2;
                }

                .date,
                .order-number,
                .payment-method {
                    padding: 0px 20px 0px 20px;
                }
            }

            .product {
                display: flex;
                justify-content: space-between;
                padding: 10px 0px 10px 0px;

                .image-details {

                    display: flex;

                    .image {

                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 10px;
                        }
                    }

                    .details {

                        padding: 0px 20px 0px 20px;

                        .name {
                            font-weight: 700;
                            font-size: 14px;
                        }

                        .pack {
                            font-size: 12px;
                            margin-bottom: 0px;
                            color: silver;
                        }

                        .quantity {
                            font-size: 12px;
                            color: silver;
                        }
                    }
                }

                .price {
                    font-size: 14px;
                    font-weight: 700;
                }
            }

            .costs {
                border-top: 1px solid #e2e2e2;
                border-bottom: 1px solid #e2e2e2;
                padding: 10px 0px 10px 0px;

                .cost {
                    display: flex;
                    justify-content: space-between;
                    color: silver;
                    font-size: 14px;
                }
            }

            .total {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                font-weight: 700;
                padding: 20px 0px 20px 0px;
            }
        }

        .continue-shopping {
            width: 216px;
            height: 40px;
            border-radius: 10px;
            background-color: #000000;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            padding: 10px 12px 10px 12px;
        }
    }
}