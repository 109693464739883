.sec-common {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include breakpoint(small) {
    flex-direction: row;
  }

  &-left {
    padding-right: 20px;
    flex: 1;

    @include breakpoint(small) {
      margin-right: auto;
    }

  }

  &-right {
    @include breakpoint(small) {
      margin-left: auto;
    }

  }
}
