.site-header {
    height: 100px;
    background-color: #f9f9f9;
    display: flex;
    max-width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    .header-container-clanshop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 1440px;

        @media screen and (max-width: 575px) {
            padding: 0px;
        }

        .logo img {
            max-width: 111px;
            height: 46px;
        }

        .discover,
        .shop {
            margin-left: 40px;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
        }

        .search-input {
            border: 1px solid #000000;
            border-radius: 16px;
            width: 309px;
            margin-left: 40px;
            height: 32px;
            position: relative;
            background-image: url("../../assets/images/icon-search.svg");
            background-repeat: no-repeat;
            background-color: #f9f9f9;
            background-position: 10px 6px;
            padding-left: 35px;
            padding-right: 15px;
            background-size: 18px 18px;

            &::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.07px;
                color: #9D9D9D;
            }
        }

        .login-icons {

            display: flex;
            align-items: center;

            .Login {
                border-radius: 18px;
                border: 1px solid #000000;
                padding: 6px 30px 6px 30px;
                width: 128px;
                line-height: 24.51px;
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                height: 37px;
            }

            .favorate-cart-icon {
                width: 76px;
                height: 36px;
                margin-left: 20px;
                display: flex;
                align-items: center;
                position: relative;

                .favorate-icon {
                    width: 24px;
                    height: 21.34px;
                    color: #1c1c1c;
                    margin-top: -3px;
                }

                .bag-icon {
                    color: #1c1c1c;
                    width: 24.73px;
                    height: 26.37;
                    margin-left: 10px;
                }

                .header-cart-count {
                    position: absolute;
                    padding: 2px 4px;
                    border-radius: 70px;
                    width: 10px;
                    height: 10px;
                    background-color: #1c1b1f;
                    font-size: 5px;
                    color: #f9f9f9;
                    font-weight: 600;
                    margin-left: -10px;
                    margin-top: 3px;
                }
            }
        }
    }
}

.drawer{

    .MuiDrawer-paper{
        justify-content: space-between;
        padding-bottom: 20px;
    }

    .affiliate-btn-div{

        padding: 0px 16px;

    .affiliate-btn{
        border: 1px solid #000000;
        padding: 2px 19px 2px 19px;
        font-size: 16px;
        border-radius: 6px;
    }
}
}


.close-logo {

    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
        font-size: 40px;
        padding-right: 20px;
    }

    .menu-logo {

        img {
            max-width: 111px;
            height: 46px;
            margin-right: 60px;
        }
    }
}

.list-item {
    margin-top: 20px;

    .account-click-item{
        margin-left: 20px;
    }

    .list-item {
        font-size: 16px;
        font-weight: 400;
    }
}