$font-weight: 400;
$font-size: 1rem;
$line-height: 1.5;
$letter-spacing: 0.00938em;


.pln_camp_main_container {
    .youtubeColor {
        color: red;
    }

    .create_new_campaign_popup {
        min-width: 400px;
        padding: 20px;
        background-color: white;
        border-radius: 10px;

        .pln_popup_select {
            margin: 10px 0px;
        }

        .plan_camp_btn_group {
            .btn {
                margin: 10px 0px 0px 10px;
            }
        }
    }

    .pln_camp_table_body {
        tr {
            th {
                min-height: 40px;
            }
        }

        .table_cell {
            display: flex;

            td {
                padding: 12px;
            }
        }

        .table-plan-name {
            min-width: 180px;
            // -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 14px;
            display: -webkit-box;

        }

        .table-plan-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 300px;
            width: 300px;
            display: inline-block;
        }

        .span_icon {
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;

                &:hover {
                    path {
                        fill: $color-menu-hover;
                    }
                }
            }

            //    display: inline-flex;
            //    border: 1px solid black;
            //    align-items: center;
            //    justify-content: center;
            //    margin: 0px 10px;
            // .icons{
            //     font-size:medium;
            //     cursor: pointer;
            // }
        }

        .table-insta-icon {
            svg {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }

    // .pln_camp_table_header {


    //     .header_text {
    //         color: white;
    //     }
    // }

    .create_plan_top_buttons {
        // margin: 0px 0px 20px 0px;
    }

    .campaign-list-table {
        padding-left: 0;
        padding-right: 0;
        // #instagram {
        //     width: 24px;
        //     height: 23px;
        // }
    }

}

.table-plan {
    thead {
        td {
            padding: 8px 12px;
        }
    }

    td {
        padding: 8px 12px;
        line-height: 1;
        font-family: open sans;
    }
}

._plan_main_tabcontent {
    border: 1px solid #000;
    border-top: none;
}

.main-plan-container ._plan_main_tabs .tabs .MuiTabs-flexContainer .MuiTab-root {
    border-bottom: 1px solid #000;
}

.plan-tabs .MuiTab-root.Mui-selected {
    border: 1px solid #000;
    margin-bottom: -1px;
    margin-top: 0px;
    position: relative;
    z-index: 22;
    border-bottom: transparent;
    background: #fff !important;
}


.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.campaign-list-table {
    padding-left: 0;
    padding-right: 0;

    .common-table-body {
        .table_cell {
            display: flex;
            min-height: 45px;
            align-items: center;
            border-left: 1px solid $color-black-7;
            font-family: $font-family-body;
        }
    }

}


.popup-select-plan {
    .popup_container-inner {
        width: clamp(350px, 50%, 550px);
    }
}