.brand_creative_main_container {
  // .Rejected {
  //     // border: 2px solid black;
  //     // display: flex;
  //     margin-top: 20px;
  //     padding: 5px 10px;
  //     border-radius: 5px;
  //     background-color: red;
  //     color: white;
  //     font-weight: bold;
  // }

  // .Approved {
  //     // display: flex;
  //     margin-top: 20px;
  //     padding: 5px 10px;
  //     border-radius: 5px;
  //     background-color: green;
  //     color: white;
  //     font-weight: bold;
  // }

  // .Pending {
  //     // display: flex;
  //     margin-top: 20px;
  //     padding: 5px 10px;
  //     border-radius: 5px;
  //     background-color: yellow;
  //     color: white;
  //     font-weight: bold;

  // }

  .selected-creative {
    // margin-top: 10px;
    max-height: 200px;
    min-height: 200px;
    object-fit: contain;
    margin: 0 auto;
    width: 100%;
    border-radius: $border-radius-card;
  }

  .send-comment {
    display: flex;
  }

  .creatives-container {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include breakpoint(small) {
      padding: 0 15px;
    }


    .creatives-container-list {
      // margin: 20px 10px;
      // display: flex;
      // flex-direction: column
      // border-bottom: 1px solid lightgray;
      // margin-bottom: 20px;
      font-size: $font-size-sml;
      // border-bottom: 1px solid $border-color-card;
      padding: 0;
      border-radius: 0;
      padding: 0;
      border-radius: 14px;
      flex: 0 0 100%;

      @include breakpoint(small) {
        flex: 0 0 calc(50% - 20px);
      }

      @include breakpoint(large) {
        flex: 0 0 calc(33.333% - 20px);
      }

      @include breakpoint(xxlarge) {
        flex: 0 0 calc(25% - 20px);
      }

      border: none;
      margin: 0 10px 20px;
      // overflow: hidden;
      position: relative;
      height: 200px;

      .creative-card {
        position: relative;
        overflow: hidden;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
          opacity: 0;
          transform: translateY(100%);
          z-index: 0;
          transition: transform 0.35s ease 0.15s, opacity 0.35s ease;
        }
      }

      // @include breakpoint(small) {
      //   border: 1px solid $border-color-card;
      //   padding: 16px;
      //   border-radius: $border-radius-card;
      // }



      .creative-card-action {
        bottom: 0;
        padding: 10px;

        width: 100%;
        border-radius: 0 0 14px 14px;
        opacity: 0;
        position: absolute;
        z-index: 1;
        transform: translateY(70px);
        transition: transform 0.35s ease 0.15s, opacity 0.35s ease;

        display: flex;
        justify-content: end;
        visibility: hidden;
        background: rgba(255, 255, 255, 0.9);

        .btn {
          margin-right: 5px;
        }
      }


      &:hover {

        .creative-card {
          &:after {
            top: 0;
            opacity: 1;
            transform: translateY(0);
          }
        }

        .creative-card-action {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }



  }
}

.comment-sec-wrapper {
  width: 100%;
  // padding: 0 10px;

  .send-comment {
    margin-bottom: 15px;
  }

  .comments-section {
    width: 100%;

    .text-field-section {
      label {
        font-size: $font-size-sml;
      }
    }
  }
}

.comments {
  padding-left: 0;
}

.comment-section {
  // border: 2px solid black;

  .text-field-section {
    display: flex
  }

  li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid $border-color-card;
    // flex-direction: column;

    &:last-child {
      border: none;
    }

    .created_at {
      font-size: small;
      font-style: italic;
    }
  }
}

.creative-detail {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-bottom: 40px;

  @media (width < 768px) {
    grid-template-columns: auto;
  }

  &.inner {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .creative-detail-img {
      max-width: 100%;
      width: 100%;

      img,
      video {
        border: 1px solid $border-creative-card;


      }


    }

  }

  .creative-detail-img {
    max-width: 100%;
    width: 100%;

    img,
    video {
      width: 100%;
      height: 450px;
      border: 1px solid $border-creative-card;
      border-right: none;
      border-radius: 0px;
      object-fit: contain;
      cursor: zoom-in;

      // @media (width < 768px) {
      //   height: auto;
      // }

      @media (width < 768px) {
        border: 1px solid $border-creative-card;

        aspect-ratio: 16 / 4;
        height: auto;
      }
    }
  }
}

.brand_creative_main_container .inf-child-section .table-plan,
.brand_creative_main_container .selector-tab {
  display: none;
}

.brand-creative-container {
  padding: 20px 0;
}