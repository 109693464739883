$activeColor: #00AAE5;

.main_campaign_main_container {
    padding: 10px;

    .brand_campaigns_breadcrumb {
        ul {
            list-style: none;
            padding: 0px;
            margin: 10px 0px;
            display: inline-flex;

            li {

                margin: 0px 10px;
                background-color: lightgray;
                padding: 0;
            }
        }

        .link {
            text-decoration: none;
            color: black;
            opacity: 0.3;

        }

        a.link {
            opacity: 1;
        }

        .complete {
            color: #00aae5 !important;
            opacity: 1;
        }


        .active {
            background-color: $activeColor !important;
            color: white !important;
        }
    }

    .brand_campaigns_pages {
        margin: 10px 0px;
    }


    @media only screen and (max-width: 1200px) {
        .brand_campaigns_breadcrumb {
            ul {
                li {
                    span {
                        .link {
                            background-color: lightgray;
                            padding: 6px 40px;
                            border-radius: 50px;
                            text-decoration: none;
                            color: black;
                        }
                    }

                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .brand_campaigns_breadcrumb {
            ul {
                li {
                    span {
                        .link {
                            background-color: lightgray;
                            padding: 6px 20px;
                            border-radius: 50px;
                            text-decoration: none;
                            color: black;
                        }
                    }

                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .brand_campaigns_breadcrumb {
            ul {
                li {
                    margin: 0px 5px;

                    span {
                        .link {
                            background-color: lightgray;
                            padding: 6px 20px;
                            border-radius: 50px;
                            text-decoration: none;
                            color: black;
                        }
                    }

                }
            }
        }
    }

    @media only screen and (max-width: 663px) {
        .brand_campaigns_breadcrumb {
            ul {
                li {
                    margin: 0px 3px;

                    span {
                        .link {
                            background-color: lightgray;
                            padding: 6px 10px;
                            border-radius: 50px;
                            text-decoration: none;
                            color: black;
                        }
                    }

                }
            }
        }
    }

    @media only screen and (max-width: 465px) {
        .brand_campaigns_breadcrumb {
            ul {
                display: flex;
                flex-direction: column;
            }
        }
    }
}