.campaign_post_main_container {

    .date_picker {
        margin-top: 10px;
    }

    .text_field {
        margin-top: 20px;
    }

    .button_group {
        .campaign_influencer_buttons {
            display: flex;
            justify-content: flex-end;

            .btn {
                margin-left: 10px;
            }
        }
    }

    .close_button {
        cursor: pointer;
    }

    .product_add {
        display: flex;

        .add_button {
            margin-left: 10px;
        }

        .remove_button {
            margin-left: 10px;
        }
    }

    .list_ul {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            li {
                // border: 2px solid black;
                display: flex;
                justify-content: space-between;
                margin: 10px 0px;
                padding: 5px 0px;
                font-weight: bolder;
                color: $color-body;

                .close_button {
                    color: red;
                }
            }
        }
    }

}

.gstin_verified {
    border-radius: 5px;
}