$color-primary: #00aae5 !default;

.card-container {
  // width: 300px;

  .plan-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .days-span {
    color: $color-primary;
    padding: 10px;
  }

  .description-span {
    padding: 10px 0px;
  }

  .price-span {
    padding: 10px 0px;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.MuiTableRow-root.subs-cancelled{
  background-color: #ffa8a8;
}