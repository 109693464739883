// $tableHeadHeight: '20px';
// $backgroundColor: #E6E6E6;
// $color-body: #2b2b2b !default;
// $color-menu-hover: #5d5d5d !default;
// $font-family-body: 'Open Sans', sans-serif !default;
// $font-size-body: 16px !default;
// $font-size-sml: 14px !default;
// $font-size-xsml: 12px !default;
// $color-primary: #00aae5 !default;
// $color-primary-hover: #36a1d1 !default;
// $backarrowColor: $color-primary !default;
// $color-secondary: deeppink !default;
// $whatsappColor: #25D366 !default;
// $errorColor: #d32f2f !default;
// $color-white: #ffffff !default;
// $width-full: 100% !default;

.influencer-detail-card {
    padding-top: 0;
    // padding: 0px;

    .inf-details-main-container {
        // border: 2px solid black;
        // min-height: 500px;

        .inf-list-section {
            border-right: 2px solid lightgray;
            // padding-right: 0px;
            // flex: 0 0 250px;
            // width: 250px;
            width: 100%;

            @include breakpoint(small) {
                width: 20%;
            }



            .search-tool {
                border-bottom: 2px solid lightgray;
                display: flex;
                align-items: center;
            }
        }

        .inf-child-section {
            padding: 0px;
            width: 80%;

            // @include breakpoint(medium) {

            //     width: calc(100% - 250px);
            // }
        }
    }
}

.inf-list {
    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid #dee2e6;

        @include breakpoint(large) {
            height: 50px;
            width: 50px;
        }
    }

    ul {
        list-style: none;
        padding: 0px;

        li {
            // border: 2px solid black;
            // margin: 10px 0px;
            padding: 5px 10px;
            font-size: $font-size-body;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;


            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .active {
            background-color: $color-black;
            color: $color-white;
        }
    }

}


.selected-inf {
    border-bottom: 2px solid lightgray;
    // border: 2px solid orange;
    display: flex;
    padding: 10px 15px;
    margin-bottom: 16px;
    width: 100%;
    flex-wrap: wrap;
    // flex-direction: column;

    &-top {
        display: flex;

        ul {
            margin-bottom: 0;
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
        }
    }

    &-btm {
        display: flex;
        margin-left: auto;
        align-self: center;

        ul {
            display: flex;
            margin-bottom: 0;

            li {
                padding-left: 10px;

                span {
                    font-size: 14px;

                    &:last-of-type {
                        font-weight: 600;
                    }
                }
            }
        }
    }


    &-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid #eee;
        font-size: 2px;
        margin-right: 10px;
    }

    img {
        height: 50px;
        width: 50px;
        display: block;
        border-radius: 50%;
        border: 1px solid #dee2e6;
        font-size: 8px;
        margin-right: 5px;
    }

    &-left {
        display: flex;
    }

    &-right {
        margin-top: 15px;
        border-top: 1px solid #e2e2e2;
        padding-top: 15px;
        width: 100%;

        @include breakpoint(large) {
            margin-top: auto;
            margin-left: auto;
            width: auto;
            border-top: none;
            padding-top: 0;
        }

        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            font-size: $font-size-sml;

            li {
                display: inline-flex;
                padding: 0 15px;
                border-right: 1px solid $border-color-card;

                &:last-of-type {
                    border-right: none;
                    padding-right: 0;
                }
            }
        }
    }
}


.inf-detail-selc-main-container {
    .selector-tab {
        overflow: hidden;
        position: relative;

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            // align-items: center;
            flex-wrap: nowrap;
            overflow-x: auto;


            li {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $border-color-card;
                background-color: $color-white;
                border-radius: $border-radius-card;
                padding: 5px 10px;
                margin-right: 5px;
                cursor: pointer;
                font-size: $font-size-sml;

                &:first-child {
                    .middle-span {
                        border-top: 1px solid $border-color-card;
                        border-bottom: none;
                    }
                }

                &:last-child {
                    margin-right: 0px;
                }

                .middle-span {
                    text-align: center;
                    border-bottom: 1px solid $border-color-card;
                    width: 100%;
                    font-size: 11px;
                }
            }

            .active {
                background-color: $color-primary;
                color: white;
                border-color: white;
            }
        }
    }

    .selector-container {
        margin-top: 10px;
    }


}

.inf-info-section {
    font-size: $font-size-sml;
}

.accordion-header {
    border-bottom: 1px solid #d2d2d2;
    min-height: 40px !important;

    .MuiAccordionSummary-content {
        margin: 0 !important;
        min-height: 40px;
    }
}

.search-tool .MuiStack-root {
    width: calc(100% - 20px);
    margin: 0px 10px;
}