.checkbox {
  // width: 100%;
  // height: 100%;
  display: grid;
  // place-items: center center;
  cursor: pointer;
  position: relative;

  .checkbox-container {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    position: absolute;

    left: 0;
    top: 0;

    display: grid;

    place-items: center center;

    &--front {

      overflow: hidden;

    }

    &--back {

      border: #000 solid 2px;

    }

  }

  .checkmark {

    fill: none;

    position: relative;

    padding-left: 18px;

    padding-bottom: 2px;

    path {

      stroke-width: 35;

      stroke-linecap: round;

    }

    &--front {

      path {

        stroke: white;

      }

    }

    &--back {

      path {

        stroke: #000;

      }

    }

  }

  .lines-container {

    height: 1.5rem;

    width: 1.5rem;

    display: flex;

    flex-direction: column;

    justify-content: space-between;

    transform: rotate(-33deg);

    border-radius: 50%;

    position: absolute;

    top: 0rem;

    right: -.5rem;

  }

  .line {

    height: 2px;

    width: 13px;

    background: #000;

    border-radius: 50%;

    transform-origin: left;

    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);

    margin-left: 2.5rem;

    &--1 {

      transform: rotate(-25deg);

    }

    &--3 {

      transform: rotate(25deg);

    }

  }

}