// .editor_components {
//     height: 200px !important;

//     .editor {
//         height: 150px;
//     }

//     // .ql-container {
//     //     height: 100%;
//     //     /* added these styles */
//     //     flex: 1;
//     //     display: flex;
//     //     flex-direction: column;
//     // }

//     // .editor {
//     //     height: 100%;
//     //     // flex: 1;
//     //     // overflow-y: auto;
//     //     width: 100%;
//     // }
// }

.editor_components {
    height: auto;

    .quill.editor {
        height: auto;
    }

    .ql-toolbar.ql-snow+.ql-container.ql-snow {
        border-top: 0px;
        height: 130px;
        border-radius: 0 0 4px 4px;
    }
}