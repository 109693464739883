.dashboard-infl {
  padding-top: 1.25rem;

  .page-title {
    span {
      font-size: $font-size-title-large-mobile;
      padding-bottom: 1rem;
      font-weight: 700;
      color: $color-body;
      line-height: 1;

      @include breakpoint(small) {
        font-size: $font-size-title-large;
      }
    }
  }

  &-info-sec {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include breakpoint(small) {
      padding-left: 2.625rem;
      padding-right: 1.875rem;

    }
  }

  .search-sec {
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto;
    align-items: center;
    margin-bottom: 1.5rem;
    grid-gap: 0.625rem;

    @include breakpoint(xsml) {

      grid-template-columns: auto auto;
    }

    strong {
      font-size: $font-size--1_25rem;

      @include breakpoint(xsml) {
        font-size: $font-size--1_5rem;
      }
    }

    .search-container {
      position: relative;

      .search-plan-input {
        width: auto !important;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;

        .MuiOutlinedInput-notchedOutline {
          border-radius: 30px;
        }
      }

      .btn {
        &.active {
          z-index: 1;
          position: relative;

          +.search-plan-input {
            width: 300px !important;
            z-index: 0;
            visibility: visible;
            opacity: 1;

            .MuiInputBase-input {
              padding-right: 85px;
              padding-left: 20px;
            }
          }
        }
      }

      .clear-search-result {
        position: absolute;
        font-size: $font-size-sml;
        color: $color-black !important;
        inset: 100% 0 0 auto;
        min-width: 135px;
      }
    }
  }

  .search-filter {
    display: inline-grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    gap: 0.625rem;

    .btn {
      height: 40px;
      min-width: 80px;
      width: 100%;

      &.active {
        color: $color-body;
        right: -40px;
        background-color: transparent;
        border-color: transparent;
        width: 36px;
        height: 36px;
        min-width: inherit;
        top: 2px;
        padding: 0;

        &:hover {
          background: rgba($color-black, 0.03);
        }

        &:after {
          content: "\F659";
          font-family: "bootstrap-icons";
          font-size: $font-size-xsml;
        }
      }
    }
  }

  .dashboard-card {

    &:nth-of-type(1) {
      border-top: none;
    }
  }
}

.dashboard-card-title-redirect-link {
  color: $color-8c8c8c;
  cursor: pointer;

  &:hover {
    color: $color-black;
  }
}

.dashboard-card {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1.5rem 1rem;
  grid-row-gap: 1rem;
  // margin-bottom: 25px;
  border-top: 1px solid $border-campaign-detail-card;
  align-items: flex-start;


  @include breakpoint(xsml) {
    grid-template-columns: 170px 1fr;
    padding: 1.5rem 2.625rem;

  }

  @include breakpoint(small) {
    grid-template-columns: 232px auto;
  }

  &-left {
    border-radius: $border-radius__10px;

    @include breakpoint(xsml) {
      margin-right: 1.5rem;
    }

    @include breakpoint(small) {
      margin-right: 3.125rem;
    }

    border: 1px solid $border-campaign-detail-card;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius__10px;
    }
  }

  &-title {
    font-size: $font-size--1_5rem;
    font-weight: 600;
  }


  &-right {
    display: grid;
    justify-content: flex-start;
    grid-gap: .625rem;

    &-top {
      display: grid;
      grid-template-columns: auto;
      grid-column-gap: 1.25rem;


      @include breakpoint(small) {
        grid-template-columns: 1fr auto;
        align-items: center;
      }

      &-left {
        justify-self: flex-start;
        display: grid;

        .badge {
          justify-self: flex-start;

          @media (width > 767px) {
            visibility: hidden;
            opacity: 0;
            height: 0;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            padding: 0 !important;

          }
        }
      }

      &-right {
        justify-self: flex-start;


        .badge {
          @media (width < 768px) {
            visibility: hidden;
            opacity: 0;
            height: 0;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            padding: 0 !important;

          }
        }
      }
    }
  }

  &.active:hover,
  &:hover {
    background-color: $dashboard-card-background-color-hover;

  }

  &.active {
    background-color: $dashboard-card-background-color-active;
  }
}


.dashboard-card-camp-dur {
  .proposal-dur-info {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-gap: 0.625rem;

    @include breakpoint(small) {
      grid-auto-flow: column;
      grid-template-columns: 160px 160px auto;
      grid-gap: 1.825rem;
    }


  }

  .infl-det-list {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 30px auto;
  }
}


.filter-content-group {
  gap: 1.25rem;

  @media (width < 36rem) {
    // border: 1px solid $border-campaign-detail-card;
    // border-radius: 4px;
  }

  &.wo-border {
    .filter-content-outline {
      border: none;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      flex: 0 0 calc(50% - 0.625rem);

      &:first-of-type {
        border-left: none;
      }

      span {
        &.Mui-checked:after {
          background: transparent;
        }
      }
    }

    // span.MuiCheckbox-root {
    //   &::before {
    //     content: "";
    //     border: 1px solid #000;
    //     width: 1.5rem;
    //     height: 1.5rem;
    //     border-radius: 4px;
    //     margin-right: 5px;
    //   }

    //   .MuiSvgIcon-root {
    //     opacity: 0;
    //     visibility: hidden;
    //     width: 0;
    //     height: 0;
    //   }

    //   &.Mui-checked {
    //     &:before {
    //       background-color: $color-black;
    //     }

    //     &::after {
    //       content: "";
    //       position: absolute;
    //       content: "";
    //       background: transparent;
    //       width: 14px;
    //       height: 8px;
    //       left: 0;
    //       top: 6px;
    //       z-index: 99;
    //       border-left: 2px solid $color-white;
    //       border-bottom: 2px solid $color-white;
    //       border-radius: 0;
    //       right: 0;
    //       left: 5px;
    //       transform: rotate(-45deg);
    //     }
    //   }
    // }
  }


}



.filter-body {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  flex-direction: column;
  font-family: $font-family-body;
  padding-bottom: 1.5rem;
  margin-bottom: 0rem;


  >span {
    margin-bottom: 1rem;
    display: flex;
    align-self: flex-start;
    margin-top: 1.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.drawer-filter {
  .MuiDrawer-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: none;

  }

  &.MuiDrawer-root {
    --Drawer-verticalSize: clamp(auto, 49%, 100%);
  }

  .MuiModalClose-root {
    top: 15px;
  }

  .MuiDrawer-content {
    max-width: 600px;
    margin: 0 auto;
    left: 0;
    right: 0;
    // transform: translateY(-50%);
    transition: transform 0.35s ease;
    margin-bottom: 3rem;
    border-radius: 8px;

    h2.MuiDialogTitle-root {
      font-size: $font-size-sml;

    }
  }
}

// $dashboard-title: 20px !default;

// .card-main-container {
//   h2 {
//     font-size: $dashboard-title;
//   }
// }

// .sec-campaign {
//   display: flex;
//   flex-wrap: wrap;
//   background: #fff;
//   border-radius: 10px;
//   padding: 10px;
//   width: 100%;
//   position: relative;
//   border: 1px solid #e2e2e2;
//   flex-direction: column;
//   margin-bottom: 15px;

//   @include breakpoint(small) {
//     flex-direction: row;
//     padding: 20px;
//     margin-top: 20px;
//   }

//   &-img {
//     flex: 0 0 auto;
//     border-radius: 8px;
//     margin-right: 0px;
//     margin-bottom: 10px;

//     @include breakpoint(small) {
//       margin-right: 20px;
//       flex: 0 0 300px;

//       margin-bottom: auto;

//     }

//     img {
//       height: 200px;
//       object-fit: contain;
//       border-radius: 8px;
//       border: 1px solid #e2e2e2;
//       width: 100%;
//     }
//   }

//   &-desc {
//     flex: 99999;
//     display: flex;
//     flex-direction: column;
//   }
// }

// .sec-campaign-desc-top {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
//   width: 100%;
//   margin-bottom: 10px;

//   @include breakpoint(medium) {
//     flex-direction: row;
//     margin-bottom: 30px;
//   }

//   &-left {
//     h1 {
//       font-size: 16px;
//       text-transform: capitalize;
//       font-weight: normal;
//       margin-bottom: 5px;
//     }

//     h2 {
//       font-size: 20px;
//       color: #00aae5;
//       overflow: hidden;
//       white-space: normal;
//       max-width: 500px;
//       text-overflow: ellipsis;
//       line-height: 25px;
//       cursor: pointer;

//       @include breakpoint(small) {
//         white-space: nowrap;
//       }

//       a {
//         text-decoration: none;
//         color: #00aae5;
//       }
//     }

//   }

//   &-right {

//     display: flex;
//     align-items: flex-start;
//     flex-wrap: wrap;
//     width: 100%;

//     @include breakpoint(medium) {
//       margin-left: auto;
//       width: auto;
//     }

//     ul {
//       display: flex;
//       margin-left: 0;
//       padding-left: 0;
//     }

//     .badge-social {
//       svg {
//         width: 20px;
//         height: 20px;
//       }
//     }

//     // .badge {
//     //   border-radius: 25px;
//     //   display: flex;
//     //   align-items: center;
//     //   padding: 4px 20px;
//     //   font-weight: 400;
//     //   font-size: 14px;
//     //   background-color: #E1E5EC;
//     //   color: #2b2b2b;

//     //   &.btn-share {
//     //     background-color: $color-primary;
//     //     color: $color-white;
//     //     width: 36px;
//     //     height: 36px;
//     //     border-radius: 50%;
//     //     padding: 0;
//     //     text-align: center;
//     //     font-size: 15px;

//     //     svg {
//     //       margin: auto;
//     //       width: 18px;
//     //       height: 18px;
//     //     }
//     //   }

//     //   &.invited {
//     //     background-color: #ee1b6c !important;
//     //     color: white;
//     //   }
//     // }
//   }
// }


// .sec-campaign-desc-bottom {
//   display: flex;
//   margin-top: auto;
//   align-items: flex-end;

//   &-right {
//     width: 100%;

//     @include breakpoint(small) {
//       width: auto;
//       margin-left: auto;

//     }

//     button {
//       width: 100%;
//     }
//   }
// }






// .middle-col-title {
//   display: block;
// }

// .middle-col-desc ul {
//   padding-left: 15px;
// }

// .middle-col-desc p>br,
// .middle-col-desc p:empty {
//   display: none;
// }

// .sec-campaign-desc-middle-col {
//   flex: 1;
// }



// .middle-col-title-inner {
//   display: block;
//   font-weight: 600;
// }

// .sec-campaign-desc-middle {
//   display: block;
//   flex-wrap: nowrap;
//   flex-direction: column;

//   @include breakpoint(medium) {

//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//   }

//   &-left {
//     flex: 0 0 60%;
//     display: flex;
//     flex-wrap: wrap;
//     flex-direction: column;

//     @include breakpoint(small) {
//       flex-direction: row;

//     }

//     .sec-campaign-desc-middle-col {

//       border-right: none;
//       border-bottom: 1px solid #e2e2e2;
//       margin-bottom: 15px;

//       @include breakpoint(small) {
//         border-right: 1px solid #e2e2e2;
//         border-bottom: none;
//         padding-right: 20px;
//         margin-right: 20px;
//         margin-bottom: 0;
//       }

//       &:last-of-type {
//         border-right: none;
//         padding-right: 0;
//         margin-right: 0;
//         border-bottom: none;

//         @include breakpoint(medium) {
//           padding-right: 20px;
//           margin-right: 20px;
//           border-right: 1px solid #e2e2e2;
//         }
//       }
//     }
//   }

//   .sec-campaign-desc-middle-col {
//     font-size: 14px;

//     strong {
//       font-size: 16px;
//     }

//   }

//   &-right {
//     flex: 0 0 40%;
//     margin-top: 15px;

//     @include breakpoint(medium) {
//       margin-top: auto;
//     }

//     .middle-col-desc {
//       overflow-y: auto;
//       max-height: 190px;
//     }
//   }
// }


.custom-checkbox {
  span.MuiCheckbox-root {
    // margin-right: 0.25rem;

    &::before {
      content: "";
      border: 1px solid #000;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      margin-right: 0.5rem;
    }

    .MuiSvgIcon-root {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    &.Mui-checked {
      &:before {
        background-color: $color-black;
      }

      &::after {
        content: "";
        position: absolute;
        content: "";
        background: transparent;
        width: 14px;
        height: 8px;
        left: 0;
        top: 6px;
        z-index: 99;
        border-left: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        border-radius: 0;
        right: 0;
        left: 5px;
        transform: rotate(-45deg);
      }
    }
  }
}



.share-sec-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem 0;

  @media (width < 35.94em) {
    flex-direction: column;
    flex-wrap: nowrap;

  }

  .share-icons {
    flex: 0 0 calc(50% - 0.5rem);
    border: 1px solid $border-campaign-detail-card;
    border-radius: 4px;

    &:hover {
      background-color: #f2f2f2;

      button {
        background-color: #f2f2f2;

      }
    }

    button {
      padding: 1rem !important;
      display: block;
      width: 100%;
      text-align: left;
      background: #fff;
      border: none;
      border-radius: 4px;
    }

    svg {
      margin-right: 8px;
    }
  }
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: 2;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}