.container {
    .back-icon {
        border-radius: 50%;
        border: 1px solid black;
        margin-left: 20px;
        margin-top: 20px;
        cursor: pointer;
        width: 45px;
        height: 45px;

        &:hover {
            background: rgb(194, 192, 192);
        }
    }

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .input {
            display: flex;
            flex-direction: column;
        }

        .otp-verify {
            margin-top: 50px;

            .title {
                margin-bottom: 10px;
            }

            .btn {
                width: 100%;
                margin: 10px 0px 10px 0px;
            }

            .change-phone {
                text-align: center;
                text-decoration: underline;
            }
        }
    }

    .bag-container {

        padding: 10px 50px 10px 50px;

        .bag-title {
            display: flex;
            justify-content: center;
        }

        .product-order {

            display: flex;

            .bag-right {
                width: 30%;
                border-left: 2px solid lightgray;
                padding: 10px;

                .title {
                    margin-bottom: 20px;
                }

                .order-value {

                    border-bottom: 1px solid black;
                    margin-bottom: 10px;

                    .order {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                    }
                }

                .total {
                    font-weight: 700;
                    display: flex;
                    justify-content: space-between;
                }

                .btn {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }
}