.uploaded-img-sec {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-top: 20px;
}

.uploaded-images {
    display: flex;
    flex-wrap: wrap;
}

.uploaded-img {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 calc(25% - 10px);
    border: 1px solid #dee2e6;
    margin-right: 10px;
}

.uploaded-img img {
    max-width: 100%;
}

.uploaded-reel-link {
    display: flex;

}

.uploaded-reel-link .btn {
    align-self: flex-start;
    height: 38px;
    margin-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
}


.reel-link-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.reel-link-list svg:hover {
    fill: #00aae5;

}

.reel-link-sec ul {
    padding-left: 0;
    font-size: 14px;
}

.reel-remove {
    cursor: pointer;
    align-self: center;
    margin-left: 10px;
}