.popup-drawer {

  .MuiPaper-elevation {
    // padding: 20px;
    width: 60%;
  }

  .popup-drawer-header {
    padding: 1.25rem 1.25rem 1.25rem 1.5rem;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    border-top: 1px solid $border-campaign-detail-card;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $color-white;
    z-index: 1;
    top: auto;

    @include breakpoint(small) {
      padding: 1.25rem 3rem 1.25rem 1.5rem;
      position: sticky;
      top: 0;
      border-top: auto;
      border-bottom: 1px solid $border-campaign-detail-card;
    }

    &-left {
      position: fixed;
      top: 20px;
      right: 10px;

      @include breakpoint(small) {
        position: static;
      }
    }



    &-right {
      gap: 0.75rem;
      display: grid;
      grid-auto-flow: column;
    }
  }

  &.drawer-infl-dashboard {
    .MuiPaper-elevation {
      width: 100%;
      border-radius: $border-radius__10px;

      @include breakpoint(small) {
        width: 60%;
        // top: 0 !important;
        // left: auto !important;
      }
    }

    .dashboard-card {
      grid-template-columns: auto;
      padding-left: 1.3125rem;
      padding-right: 1.125rem;
      border-bottom-color: $border-campaign-detail-card ;
      margin-bottom: 1.25rem;
      border-top: none;

      &:hover,
      .active,
      .active:hover {
        background-color: transparent;
      }
    }

    .popup-drawer-body {
      padding-left: 1.4375rem;
      padding-right: 2.25rem;
      height: calc(100svh + 150px);

      .camp-detail-card {
        &:last-of-type {
          // margin-bottom: 5rem;
          padding-bottom: 6rem;
        }
      }
    }

    .camp-detail-card-inner.grid-3-col {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 1rem;

      @include breakpoint(small) {
        grid-gap: 52px;
      }
    }

    .inf-description {
      display: inline-block;
      overflow: visible;
      text-overflow: inherit;
      display: block;
      -webkit-line-clamp: initial;
      line-clamp: initial;
      -webkit-box-orient: inherit;

      .description {
        overflow: initial;
        text-overflow: initial;
        display: initial;
        -webkit-line-clamp: initial;
        line-clamp: initial;
        -webkit-box-orient: initial;
      }
    }

    .camp-detail-card {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
}