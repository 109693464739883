.title-w-bdr {
    border-bottom: 1px solid transparent;

    font-size: 24px;
    font-weight: 700;
    position: relative;
    color: #2b2b2b;
    line-height: 1;

    span {
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        text-align: left;

        // &:after {
        //     position: absolute;
        //     content: "";
        //     width: 35%;
        //     padding-right: 20px;
        //     height: 1px;
        //     background-color: #eb008b;
        //     left: 0;
        //     top: 100%;
        // }
    }
}

.title-secondary {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}