.header-premium {
  // border: 2px solid black;
  padding: 0px;
  margin: 0px;
  li {
    padding: 0px !important;
    margin: 0px !important;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    &:last-child {
      color: gray;
    }
  }
}

.leftside-border{
    border-right: 1px solid gray;
}

.guest_user_avatar{
  align-items: center;
  display: flex;

  .guest_user_pic{
    img{
    height: 42px !important;
    width: 42px !important;
  }
}

  .email{
    font-size: 14px;
  }
}
