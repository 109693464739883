.popup_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: fixed;
    z-index: 99;
    min-height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(1000%);
    transition: all 0.35s ease;
    height: 100vh;
    overflow-y: auto;
    // background-color: rgba(0, 0, 0, 0.6);
}

.popup_container_content {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    border-radius: $border-radius__10px;
    max-height: clamp(500px, 70vh, 100vh);
    // max-height: 90vh;
    // max-height: 90vh;
}

.popup_content_container {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    border: '2px solid #000';
    background-color: #fff;
    padding: 2rem 2rem 0;
}

.popup_container-inner {
    position: relative;
    background: #fff;
    border-radius: $border-radius__10px;
}

.popup-header {
    position: sticky;
    top: 0;
    z-index: 9;
    background: $color-white;
    width: 100%;
    padding: 1rem 2rem;
    border-radius: $border-radius__10px $border-radius__10px 0 0;
    border-bottom: 1px solid $border-campaign-detail-card;
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    // .MuiIconButton-root {
    //     top: 1.5rem;
    //     right: 2rem;
    // }
    strong {
        font-size: $font-size-body;
        font-weight: normal;
    }

    .popup-close {
        margin-left: auto;
    }
}

.MuiModal-root {
    .popup_container-inner {
        width: 100%;
    }

    &.modal-large {
        .popup_container-inner {
            max-width: 800px;
        }
    }

    &.modal-medium {
        .popup_container-inner {
            max-width: 600px;
        }
    }
}

.popup-footer {
    position: sticky;
    bottom: 0;
    padding: 1.5rem 2rem;
    margin-left: -2rem;
    width: calc(100% + 4rem);
    margin-top: 3rem;
    border-top: 1px solid $border-campaign-detail-card;
    background: $color-white;
}

.creative-popup {
    position: fixed;

    top: auto !important;

    height: auto;
    // max-height: 90vh;
    max-height: calc(100vh - 12vh);
    overflow: hidden;
    overflow-y: auto;
    background: #fff;
    border-radius: 16px 16px 0 0;
    // transform: scale(0) translateY(100%);
    // transition: all 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    // transform-origin: center bottom;

    // &[aria-hidden="true"] {
    //     transform: scale(1) translateY(0%);
    //     transition: all 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    // }

    .popup_container {
        justify-content: flex-start;

        background: #fff;
        height: 100%;
        min-height: inherit;
        border-radius: 16px 16px 0 0;

    }

    .popup-header {
        border-radius: 16px 16px 0 0;
        box-shadow: none
    }

    .popup_container-inner {
        width: 100%;
        background-color: transparent;
    }

    .modal-content {
        border: none;
        border-radius: 0;
    }

    .popup_container_content {
        overflow: inherit
    }

    .creative-detail {
        padding-bottom: 40px;
    }

    .modal-dialog-scrollable {
        .modal-body {
            background-color: $color-white;
            border: 1px solid $border-creative-card;
            border-bottom: none;
        }
    }
}


.creative-img-popup {
    .popup_container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .popup_container-inner {
        height: 100%;
        width: 100%;
    }

    .popup-header {
        padding: 0;
    }

    .selected-creative {
        height: auto;
    }

    .creative-detail {
        .creative-detail-img {
            img.selected-creative {
                height: auto;
            }
        }
    }
}

.creative-popup .MuiModal-backdrop {
    background: transparent;

}

.bodyActive {
    position: relative;

    &::after {
        background: rgba(0, 0, 0, 0.5);
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999;
        top: 0;
        left: 0;
    }

}

.popup {
    margin: 0 auto;
    max-width: 1000px;

    &.popup-sm {
        max-width: 500px;
    }

    .popup_content_container,
    .popup_container-inner {
        border-radius: 0.25rem;
    }
}

.MuiModal-open {
    .popup_container {
        transition: all 0.35s ease;
        transform: translateY(0%);
    }
}