$borderSize: 20px;
$borderRadius: 10px;
// $color-primary: #00AAE5;
$boxSize: 170px;

.create_campaign_main_container {

    .create_campaign_main_row {
        padding: 10px 0px;


        .buttons {
            display: flex;
            margin-top: 15px;
            margin-bottom: 15px;
            justify-content: flex-end;

            .btn {
                margin-left: 10px;
            }
        }




        .create_campaign_upload_photo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: lightgray;
            cursor: pointer;

            .icon {
                color: $color-primary;
            }

            .upload_text {
                color: $color-primary;
            }
        }


    }

    @media only screen and (max-width: 1200px) {

        .buttons {
            // flex-direction: column;
            // justify-content: center;
        }

        .campaign_type {
            // flex-direction: column;
            flex-wrap: wrap;
        }

    }


}


.cover_image_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px;
    border: 1px solid $color-border-light;
    padding: 30px 15px;
    border-radius: 4px;

    @include breakpoint(small) {
        height: calc(100% - 0px);
    }

    .upload_button {
        margin-top: 10px;
    }

    .campaign_cover_image {
        // border: 1px solid $color-border-default ;
        object-fit: cover;
        height: 100%;
        width: 100%;
        max-height: 200px
    }
}

// CAMPAIGN TYPE
.campaign_type {}

.campaign_type {
    display: flex;
    // margin-top: 10px;
    flex-wrap: wrap;
    margin: 30px 0;

    span {
        border: 1px solid $color-black-7;
        padding: 20px;
        border-radius: 10px;
        width: calc((100% / 8) - 9px);
        text-align: center;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white;

        &.active,
        &:hover {
            background-color: $color-black;
            color: $color-white;
            transition: .6s;
        }

        &:last-of-type {
            margin-right: 0;
        }

    }

}

// CAMPAIGN PLATFORM
.create_campaign_platform {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;


    @include breakpoint(xsml) {

        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    strong {
        margin-bottom: 10px;
        flex: 0 0 100%;

        @include breakpoint(xsml) {
            margin-bottom: 0;

        }
    }

    span {
        // border: 1px solid $color-border-default;
        // border-radius: 4px;
        // padding: 8px 20px;
        cursor: pointer;
        // display: flex;
        // align-items: center;
        // margin-right: 10px;


        svg {
            display: inline-flex;
            margin-right: 5px;
        }

        &.active,
        &:hover {
            // background-color: $color-black;
            // color: white;
            // border-color: $color-black-7;
            // transition: .6s;

            // svg {
            //     path {
            //         fill: $color-white;
            //     }
            // }
        }
    }


}

// 
.MuiMenu-paper {
    .MuiMenu-list {
        .campaign-desc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid #dee2e6;
            padding: 10px 15px;
        }
    }
}

#select-campaign-type {
    padding-top: 8.5px;
    padding-bottom: 8.5px;

}

#select-campaign-type-label {
    transform: translate(14px, -9px) scale(0.75);
}

.currencyIcon {
    width: 18px;
    height: 18px;
}

.create_campaign_editor {
    margin-bottom: 15px;

    .editor_components .editor,
    .editor_components {
        height: auto !important;
    }

    .ql-toolbar.ql-snow+.ql-container.ql-snow {
        height: 150px;
    }

    @include breakpoint(small) {
        margin-bottom: 0;
    }
}

.brand_campaigns_breadcrumb {

    li,
    p {
        font-size: $font-size-sml;
        font-family: $font-family-body;
    }
}