.container {

    .tabs {
        display: flex;
        justify-content: center;

        .tabs-box {
            display: flex;
            justify-content: center;
            width: 486px;

            .tab {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media screen and (max-width: 639px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 10px;
                }
            }
        }
    }

    .profile-div {
        display: flex;
        align-items: center;
        justify-content: center;

        .profile-image {
            overflow: hidden;

            .img {
                width: 140px;
                height: 140px;
                border-radius: 70px;
                margin-right: 5px;
                display: inline-flex;
                object-fit: cover;
            }
        }

        .profile-details {
            display: flex;
            flex-direction: column;
            width: 300px;
            margin-left: 60px;
            flex-wrap: wrap;

            @media screen and (max-width: 550px) {
                margin-left: 10px;
                
            }

            .followers,
            .description {
                font-size: 14px;
                font-weight: 600;
                line-height: 19.07px;
                word-break: break-word;
            }

            .name-follow {
                width: 267px;
                height: 27px;
                display: flex;
                font-size: 18px;
                justify-content: space-between;

                .name {
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 27.24px;
                    color: #000000;
                }

                .btn {
                    width: 87px;
                    height: 26px;
                    border-radius: 6px;
                    padding: 2px 19px 2px 19px;
                    background-color: #000000;
                    color: #ffffff;
                    font-weight: 400;
                }
            }
        }
    }

    .all-product {
        max-width: 1166px;
        margin: 0 auto;
    }

    .shop-slider {
        max-width: 1166px;
        margin: 0 auto;
    }

    .products {
        padding: 20px 10px;

        .title {
            font-weight: 600;
            font-size: 14px;
            padding: 10px 20px 0px 20px;
            line-height: 19.07px;
            margin: 0px;
        }

        .product {

            padding-top: 8px;

            .image-div {
                border-radius: 10px;

                :hover {
                    cursor: pointer;
                }

                .image {
                    border-radius: 10px;
                    height: 320px;
                    width: 100%;
                    object-fit: cover;
                    border: 1px solid #d7d7d7;
                }
            }

            .details {
                display: flex;
                justify-content: space-between;

                .name-price {

                    font-size: 14px;

                    .name {
                        font-weight: 700;
                        margin-bottom: 2px;
                    }

                    .category {
                        color: grey;
                        margin-bottom: 2px;
                    }

                    .price {
                        font-weight: 700;
                    }
                }

                .icons {
                    padding: 4px 6px 4px 6px;

                    .icon {
                        width: 29px;
                        height: 29px;
                    }
                }
            }
        }
    }

    .shop-slider {
        padding: 20px;

        .slick-prev:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 25px;
            line-height: 1;
            opacity: .75;
            color: black;
        }

        .image {
            border: 1px solid #d7d7d7;
        }
    }


    .mobile {

        width: 100%;
        margin:0 auto;

        .mobile-profile-div {

            display: flex;
            flex-direction: column;

            .description{
                font-size: 14px;
                font-weight: 400;
                margin: 10px 0px;
            }

            .profile-image-details {

                display: flex;

                .profile-details {
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;

                    .name{
                        font-size: 16px;
                        color: #000000;
                        font-weight: 700;
                    }

                    .describe{
                        font-size: 16px;
                        color: #818181;
                    }

                    .followers{
                        font-size: 14px;
                    }
                }

                img {
                    width: 83px;
                    height: 83px;
                    border-radius: 70px;
                }
            }

            .mobile-follow-btn {
                border-radius: 6px;
                padding: 2px 19px 2px 19px;
                background-color: #000000;
                color: #ffffff;
                font-size: 16px;
                width: 100%;
                margin-bottom: 10px;
            }

        }
    }
}
@media only screen and (min-width:768px) {
    .mobile {
        display: none;
    }
}
@media only screen and (max-width:767px) {
    .mobile {
        display: block;
    }
    .profile-div {
        display: none !important;
    }
}