.upload-file {
    &-sec {
        // width: 100%;
        // position: relative;
        // height: 80px;
        // // margin-bottom: 20px; 
        // z-index: 1;
        // border: 1px solid #000;
        // border-radius: 8px;
        // background: #fff;
        // margin-right: 20px;
        width: auto;
        position: relative;
        height: auto;
        z-index: 1;
        border: 1px solid #000;
        background: rgba(0, 0, 0, 0.8);
        margin-right: 0;
        color: #fff;
        transition: all .35s ease;
        padding: 0px 15px;


        &:after {
            content: "Upload";
            // display: flex;
            // justify-content: center;
            // align-items: center;
            width: 100%;
            font-weight: 700;
            padding-left: 10px;
            position: relative;
            top: -8px;
        }

        &:before {
            content: "\F603";
            font-family: "bootstrap-icons";
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // padding-top: 10px;
            font-size: 30px;

            display: inline-block;
        }

        &:hover {
            background: #fff;
            color: #2b2b2b;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);


        }
    }

    &-input {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        opacity: 0;
        z-index: 2;
        height: 100%;
        cursor: pointer;
        top: 0;
        left: 0;
    }


}

.upload-file-btn.MuiButton-root {
    position: relative;
    z-index: 9;
    color: #fff;
    font-family: "Open Sans";
    text-transform: none;
    width: 126px;
    height: 45px;
    letter-spacing: normal;
    font-weight: normal;
    background: rgba(0, 0, 0, 0.8);

}

.img-upload-sec {
    width: 200px;
    border: 1px solid #e2e2e2;
    height: 100px;
    position: relative;
    z-index: 0;
    padding: 0;
    border-radius: 8px;
    display: block;


    &:after {
        position: absolute;
        content: "UPLOAD";
        top: 10px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &:before {
        position: absolute;
        font-family: "bootstrap-icons";
        content: "\f603";
        top: -10px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .img-upload {
        opacity: 0;
        font-size: 10px;
        height: 100%;
        width: 100%;
        cursor: pointer;
        display: block;
    }

    .MuiSvgIcon-root {
        font-size: $font-family-body;
        margin-right: 5px;
    }

    &:hover {
        background-color: $color-page-background;
    }
}