.breadcrumb {
    ul {
        list-style: none;
        padding: 0px;
        margin: 10px 0px;
        display: inline-flex;

        li {

            margin: 0px 10px;
            background-color: lightgray;
            padding: 0;
        }
    }

    p.link {
        text-decoration: none;
        color: $color-black;
        opacity: 0.5;

    }

    a.link {
        opacity: 1;
        text-decoration: none;
        color: $color-black;
    }

    .complete {
        color: $color-black-7 !important;
        opacity: 1;
    }


    .active {
        background-color: $color-black !important;
        color: white !important;
    }

    .MuiBreadcrumbs-separator {
        color: $color-border-default;
    }
}