.__login_container {
  @media only screen and (max-width:575px) {
    margin: 0 15px;
  }

  .__login_row {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 14px;
    min-height: inherit;
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;

    @include breakpoint(small) {
      min-height: calc(100vh - 100px);
      margin-top: 100px;

    }


    .__login_box {
      border-radius: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      // background-color: #fff;
      padding: 20px;
      // border: 1px solid rgba(214, 214, 214, 0);
      max-width: 420px;
      // box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      /* From https://css.glass */
      background: rgba(255, 255, 255, 0.24);
      border-radius: 4px;
      // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(0, 0, 0, 0.1);

      .title-w-bdr {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
      }

      .btn-send-otp {
        position: absolute;
        right: 1px;
        top: 1px;
        background-color: #25D366;
        z-index: 9999;
      }

      .__login_auth_span {
        // border: 1px solid black;
        // padding: 0px 10px;
        margin-bottom: 0px;
        border-radius: 10px;
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        display: flex;
        // justify-content: center;
        align-items: center;
      }

      .__login_text {
        font-size: 20px;
        padding-bottom: 20px;
        font-weight: bold;
      }


      .login_remember {
        margin: 20px 0px 0px 0px;
      }



      .login_buttons {
        margin: 20px 0px 0px 0px;
        text-align: center;

        .__login_button {
          margin-top: 20px;
          margin: 0px 10px;
        }
      }
    }

  }

}

.input-common {

  // input,
  // .MuiInputBase-root {
  //   background-color: rgba($color-black, 0.02);
  // }
  .MuiInputBase-root {

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba($color-black, 1);
      }
    }
  }

  .MuiFormLabel-root {
    &.MuiFormLabel-filled {
      +.MuiInputBase-root {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-border-default;
        }
      }
    }

  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba($color-black, 0.23);
  }

  .MuiInputLabel-root {
    &.Mui-focused {
      color: $color-body;
    }

  }
}