.container {
    .ifram-container{
        display:flex
       
    }.iframe-cont{
        margin :10px;
    }
    .ProductDetails-container {
        padding: 10px 50px;

        .name-following {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 40px;
            border-bottom: 1px solid #d7d7d7;
            max-width: 1240px;

            .image-name-followers {
                display: flex;

                img {
                    width: 53px;
                    height: 53px;
                    border-radius: 70px;
                    margin-right: 5px;
                    display: inline-flex;
                    object-fit: cover;
                    margin-right: 10px;
                }

                .name-followers {
                    display: flex;
                    flex-direction: column;

                    .name {
                        font-size: 20px;
                        font-weight: 800;
                        line-height: 27.24px;
                    }

                    .followers {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 19.07px;
                    }

                }
            }

            .following-btn {
                padding: 2px 19px 2px 19px;
                border: 1px solid #000000;
                border-radius: 6px;
            }

        }

        .product-details {
            margin-top: 10px;
            max-width: 1180px;

            .product-details-left {
                padding: 10px;
                display: flex;
                flex-wrap: wrap;


                .product-image {

                    padding: 5px;

                    img {
                        width: 100%;
                        border-radius: 10px;
                        height: 240px;
                        border: 1px solid #d7d7d7;
                    }
                }
            }

            .product-details-right {
                padding: 10px 10px 10px 20px;

                .product-name {
                    display: flex;
                    justify-content: space-between;

                    .name {
                        font-weight: 700;
                        font-size: 24px;
                        margin-bottom: 0px;
                    }

                    .category {
                        color: #5d5d5d;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }

                .product-price {

                    .price {

                        margin-bottom: -5px;

                        .old-price {
                            font-size: 20px;
                            margin-right: 10px;
                            font-weight: 600;
                            color:#5d5d5d;
                        }

                        .new-price {
                            font-size: 20px;
                            margin-right: 10px;
                            font-weight: 600;
                        }

                        .discount {
                            color: #c13515;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }

                    .mrp {
                        font-size: 13px;
                        font-weight: 400;
                        color: #5d5d5d;
                    }

                }

                .product-color {
                    font-size: 13px;
                    font-weight: 600;

                    .color-boxes{
                        display: flex;

                       div{
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        border: 1px solid #8c8c8c;
                        margin-right: 5px;
                       }

                       .color-box1{
                        background: #ffffff;
                       }

                       .color-box2{
                        background: #D1EDF3;
                       }

                       .color-box3{
                        background: #E5BCB4;

                       }
                    }
                }

                .product-size {
                    .size {
                        display: flex;
                        justify-content: space-between;
                        font-size: 13px;
                        font-weight: 600;
                    }
                }

                .cart-button {
                    border-radius: 10px;
                    border: 1px solid #000000;
                    background-color: #000000;
                    padding: 5px 26px 5px 26px;
                    height: 40px;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 15px;
                }

                .description-fit {

                    border-top: 1px solid #d7d7d7;
                    border-bottom: 1px solid #d7d7d7;
                    margin-top: 20px;

                    .MuiButtonBase-root{
                        padding: 0;
                    }
                }

                .materials {
    
                    .MuiButtonBase-root{
                        padding: 0;
                    }
                }
            }
        }


    }

    .product {
        padding: 5px;
    }

    .product-details-slider {
        padding: 20px;

        .slick-prev:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 25px;
            line-height: 1;
            opacity: .75;
            color: black;
        }

        .image {
            border: 1px solid #d7d7d7;
        }
    }

    .product-content {

        .image {
            border: 1px solid #d7d7d7;
        }

        .details {
            display: flex;
            align-items: center;

            .name-price {
                display: flex;
                align-items: center;

                .img {
                    width: 42px;
                    height: 42px;
                    border-radius: 70px;
                    margin-right: 5px;
                    display: inline-flex;
                    object-fit: cover;
                    margin-right: 10px;
                }

                .name {
                    font-weight: 600;
                    font-size: 14px;
                    color: #000000;
                    line-height: 19.07px;
                }
            }
        }
    }

    .product-details-slider {
        width: 1166px;
        margin: 0 auto;
        .products{

        .title {
            padding: 0px 10px 0px;
        }
    }
    }

    .product-content {
        width: 1166px;
        margin: 0 auto;

        .products{

            .title{
                padding: 0px 10px 0px;
            }
        }
    }
}