.creative-card {
  display: flex;
  width: 100%;
  border: 1px solid $border-color-card;
  border-radius: $border-radius-card;
  position: relative;

  .creative-status {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: $font-size-sml;
    color: white;
    z-index: 1;

    &.Rejected {
      background-color: $errorColor;

    }

    &.Approved {
      background-color: $whatsappColor;

    }

    &.Pending {
      background-color: $color-pending;
      color: $color-body;

    }
  }

  video {
    height: 340px;
    object-fit: contain;
    width: 100%;

    border-radius: $border-radius-card;
  }

  .creative_size {
    max-width: 100%;
    height: 340px;
    object-fit: contain;
    cursor: pointer;
    border-radius: $border-radius-card;
    width: 100%;
    padding: 15px;
  }

  .creative-date {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 1;
  }

}

.selectedCreative {
  &.active {
    z-index: 10;
    width: calc(100% - 0px);

    @include breakpoint(small) {
      width: calc(100% - 244px);
    }

    @include breakpoint(medium) {
      width: calc(35% - 2.5rem);
    }
  }

  .selected-creative-img {
    margin: 10px auto;
    max-width: 250px;
  }

  video {
    margin: 0 auto;
  }
}