// $color-primary: #00aae5 !default;
$errorColor: #d32f2f !default;

$color-border-light: #dee2e6 !default;

.influencer-list-main-container {
  padding-top: 15px;

  .btn-outline-primary {
    margin-left: 15px;
    align-self: flex-start;
  }

  .close-icon {
    color: $color-primary;
    cursor: pointer;
  }

  .icon {
    margin-right: 10px;

    &.icon-youtube {
      font-size: $font-size--1_5rem;
      color: $color-red;
    }
  }

  .edit-icons {
    font-size: $font-size--1_5rem;
    margin-left: 15px;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }

  .create-list-card {
    max-width: 600px;
    min-width: 400px;
  }

  .btn-primary {
    margin-left: 10px;
  }

  .inf-list-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .list-section {
    // background-color: lightgray;
    // min-height: 100vh;
    // margin-left: 10px;
    margin-top: 20px;
    // flex: 0 0 280px;
    // margin-right: 15px;
    border-right: 1px solid $color-border-light;

    @media only screen and (max-width: 991px) {
      min-height: inherit;
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      max-height: 100%;
      overflow-y: auto;
      height: 100%;


      li {
        // border: 2px solid rgb(248, 192, 89);
        padding: 10px 10px;
        cursor: pointer;
        display: flex;
        border: none;
        border-radius: 0;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        transition: 0.1s ease-out;

        .list-section-icon {
          margin-right: 10px;
        }

        .influencer_table_instagram_icon {
          // width: 24px;
          // height: 24px;
        }

        .influencer_table_del_icon {
          color: $errorColor;
          margin-left: auto;
        }
      }

      .active {
        background-color: $color-black;
        color: white;
        transition: 0.1s ease-in;
        border-color: $color-black-7;

        .list-section-icon {
          color: #fff;

          svg path {
            fill: #fff;
          }
        }

        &:hover {
          background-color: #f2f2f2;
          border-color: #f2f2f2;
          color: #2b2b2b;

          .list-section-icon {
            color: #fff;

            svg path {
              fill: #2b2b2b;
            }
          }
        }
      }
    }
  }
}

.influencer_table_main_container {
  padding: 0px;
  margin: 0px;

  .brand_influencer_table_upper_row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .influencer_table_instagram_icon {
    color: rgb(197, 32, 81);
  }

  .influencer_table_edit_icon {
    color: rgb(0, 110, 255);
  }

  .brand_influencer_table_button {
    // border: 2px solid orangered;
    display: flex;
    justify-content: flex-end;

    .influencer_list_sendbreif {
      margin-right: 30px;
    }
  }

  .MuiDataGrid-columnHeaders {
    background-color: $color-black;
    color: $color-white;
    border-color: $color-black-7;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: normal;
    font-size: 14px;
    text-align: left;
  }

  .util-table-list {
    .MuiTablePagination-root {
      color: #00aae5;
    }
  }
}

.table-section-title {
  display: flex;
  align-items: center;

  .title-w-bdr {
    span {
      padding-bottom: 0;
    }
  }

  .icon-instagram {
    width: 26px;
    height: 26px;
    margin-right: 10px !important;
  }
}

.table-section-title-sec {
  display: flex;
  flex-wrap: wrap;
}

.table-section-title-right {
  margin-left: auto;
}

.platform_type {
  gap: 1.25rem;

  span {
    svg[data-testid="YouTubeIcon"] {
      fill: $color-red;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: #f2f2f2;
    }
  }

  // span {
  //   border: 1px solid lightgray;
  //   padding: 10px 20px;
  //   border-radius: 5px;
  //   cursor: pointer;

  //   &:first-child {
  //     margin-right: 10px;
  //   }
  // }

  // .active {
  //   border-color: $color-primary;
  //   background-color: rgba(0, 170, 229, 0.2);

  //   .list-section-icon {
  //     svg {
  //       path {
  //         fill: #fff;
  //       }
  //     }
  //   }
  // }
}