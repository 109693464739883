.MuiCardMedia-media {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    font-size: 8px;
    line-height: 55px;
}

.infl-signup-profile-card {
    border: 1px solid $border-color-card;

    .MuiCardActionArea-root {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 10px;
    }

    .MuiCardMedia-img {
        width: 60px;
        margin-right: 20px;
    }

    .MuiCardContent-root {
        padding: 0;

        h3 {
            font-size: 18px;
            font-weight: 700;
        }
    }
}

._influencer-signup form p.infl-signup-profile-username {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 20px;
}

.infl-signup-profile-media,
.infl-signup-profile-follower,
.infl-signup-profile-platform {
    font-size: 14px;
    padding-bottom: 10px;

}

.infl-signup-profile-media span:first-of-type,
.infl-signup-profile-follower span:first-of-type,
.infl-signup-profile-platform span:first-of-type {
    padding-right: 9px;
    font-weight: 600;
    min-width: 100px;
    display: inline-flex;
}

.profile-img-common {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.small {
        width: 30px;
        height: 30px;
    }
}