.search-div {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    .search-input {
        width: 100%;
        border: 1px solid #000000;
        border-radius: 5px;
        height: 32px;
        position: relative;
        background-image: url("../../assets/images/icon-search.svg");
        background-repeat: no-repeat;
        background-color: #f9f9f9;
        background-position: 10px 6px;
        padding-left: 35px;
        padding-right: 15px;
        background-size: 18px 18px;

        &::placeholder {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.07px;
            color: #9D9D9D;
        }
    }
}