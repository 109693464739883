$color-primary: #00aae5 !default;

.brand_proposal {
    .proposals_tool {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        ul {
            display: flex;
            list-style: none;
            margin: 0px;
            margin-bottom: 0px;
            padding: 0px;
            flex-wrap: wrap;

            .active {
                color: $color-primary;
            }

            li {
                display: inline-flex;

                font-size: $font-size-sml;
                cursor: pointer;
                margin: 0 5px;

                @include breakpoint(small) {
                    margin: 0 5px;
                }

                @include breakpoint(medium) {
                    font-size: $font-size-body;
                    margin: 0 10px;
                }

                @include breakpoint(large) {
                    margin: 0px 30px 0px 10px;
                    font-size: $font-size-body;

                }

            }
        }

        >.btn {
            margin-left: auto;
        }

        .MuiFormControl-root {
            min-width: 160px;
        }
    }

    .inf-detail-selector {
        margin: 10px 0px 10px 0px;
    }
}

.input-style {
    &.input-select-small {
        .MuiSelect-select {
            padding-top: 4px;

            padding-bottom: 4px;

        }
    }
}