#content5,
#content2 {
  position: relative;
}

#content5 {
  width: 100%;
  padding: 0 0 20px 0;
  height: 100%;
  transition: all 0.3s;
  background: transparent;
}

#content2 {
  width: 100%;
  padding: 20px 0 20px 0;
  height: 100%;
  transition: all 0.3s;
  background: transparent;
}

.section-1 {
  padding: 0 0px 40px 0px;
  overflow: hidden;
  background: #fff;

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

.custom-container {
  max-width: 1280px;

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.influ-prof {
  text-align: center;
}

.influ-prof-img {
  margin: 0 auto 15px;
  border-radius: 50%;
  position: relative;
  max-width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #dee2e6;


  img {
    vertical-align: middle;
    border-style: none;
    margin: 0 auto;
    border-radius: 50%;
    max-width: 150px;
    height: 150px;
  }
}

.influ-prof-verified-img {
  position: absolute;
  bottom: 0;
  right: 14px;
  width: 30px;
  height: 30px;
  z-index: 0;

  img {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 12px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: #fff;
  }

  img:last-of-type {
    left: 7px;
    top: 7px;
    z-index: -1;
  }
}

.influ-prof-info {
  text-align: center;

  h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.influ-prof-info .influ-prof-info-social-chan {
  color: $color-primary;
}

.influ-prof a {
  font-size: 16px;
  text-align: center;
  color: $color-primary;
}

.influ-prof-info-loc {
  list-style: none;
  padding-left: 0;
  margin-bottom: 10px;
}

.info-prof-flw-data {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding-left: 0;
}

.info-prof-flw-data li:first-of-type:not(:only-of-type) {
  text-align: right;
}

.info-prof-flw-data li:not(:only-of-type) {
  flex: 1;
  padding-right: 30px;
  position: relative;
  color: #4c4c4c;
}

.info-prof-flw-data li .flw-title {
  display: flex;
  flex-flow: column wrap;
}

.info-prof-flw-data li .flw-count {
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
}

.info-prof-flw-data li:first-of-type:not(:only-of-type):after {
  content: "";
  background: #c9c9c9;
  width: 2px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.info-prof-flw-data li:last-of-type:not(:only-of-type) {
  padding-left: 30px;
  padding-right: 0;
}

.influ-subheading {
  font-size: 14px;
  color: #797979;
  display: block;
  margin-bottom: 5px;
}

.influ-summary>ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.influ-topdisc {
  border-bottom: 1px solid #e6e6e6;
  padding-top: 10px;

  p {
    color: #5d5d5d;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 0;
  }
}

.influ-toprighttxt span,
.influ-topdisc span,
.influ-topcat-dtls span {
  color: #5d5d5d;
  font-size: 14px;
}

.influ-stats-wrap {
  display: flex;
}

.influ-stats-wrap>.influ-stats {
  padding-right: 25px;
  border: solid 1px #c9c9c9;
  padding-left: 12px;
  margin-right: 15px;
  padding-top: 10px;

  p {
    color: #4c4c4c;
    font-size: 14px;
    margin-bottom: 7px;
    font-weight: bold;
  }

  span {
    color: #4c4c4c;
    font-size: 14px;
    font-weight: normal;
  }
}

//2

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.add-list-class {
  vertical-align: top;
}

#section-2-row-1,
#section-2-row-2,
#section-2-row-3 {
  padding: 5px 50px 5px 65px;
  background: #f9fafc;
}

.audience-type-wrapper {
  background: #fff;
  border-radius: 10px;
  padding: 12px 30px 0 30px;
  width: 100%;
}

.audience-type-wrapper.engg {
  height: 100%;
}

.audience-type-wrapper.engg table {
  width: 100%;
  border-collapse: collapse;
}

.audience-type-wrapper.engg tr td:last-of-type {
  text-align: right;
  width: 20%;
}

.audience-type-wrapper.engg td {
  font-size: 14px;
}

.audience-type-wrapper table td {
  padding: 4px 0;
}

.section-header.sml {
  font-size: 16px;
}

.section-header {
  font-size: 18px;
  font-weight: bold;
  color: #4c4c4c;
  padding-bottom: 10px;
}

.section-header .section-header-symbol {
  border: none;
  font-weight: normal;
  font-size: 16px;
  padding-left: 4px;
}

.section-header>span {
  display: inline-block;
  border-bottom: 1px solid #4c4c4c;
}

#section-2 table {
  width: 100%;
  color: #2a2a2a;
  font-size: 14px;
}

.cities-tab-sec {
  display: flex;
  justify-content: space-between;

  .city_tab_1 {
    border: 1px solid #dee2e6;
    border-radius: 20px 0 0 20px;
    min-width: 50px;
    padding: 0 12px;
  }

  .city_tab_2 {
    border: 1px solid #dee2e6;
    border-radius: 0 20px 20px 0;
    min-width: 48px;
    padding: 0;
  }

  .MuiBox-root {
    border-bottom: none;
    background-color: none;
  }

  .MuiTabs-indicator {
    height: 0px;
  }

  .MuiTab-root {
    min-height: 26px;
    font-size: 12px;
    margin-top: 11px;
    font-weight: 400;
  }

  .MuiTabs-flexContainer {
    .MuiTab-root.Mui-selected {
      background: $color-black;
      color: #fff;
    }
  }
}

#section-2 ul {
  display: flex;
  justify-content: space-around;
}

#section-2 #cities-tab .nav-link.active {
  background: #00aae5 !important;
  color: #fff !important;
  border-color: #00aae5 !important;
}

#section-2 .nav-tabs .nav-link.active {
  border-bottom: 1px solid #00aae5 !important;
  color: #00aae5 !important;
  background-color: #fff !important;
  border: none;
}

#section-2 .nav-tabs .nav-link {
  color: #a1a1a1 !important;
  background-color: #fff !important;
  border: none;
  width: 100%;
  display: block;
  text-decoration: none;
}

#cities-tab li:first-of-type a {
  border-radius: 20px 0 0 20px;
}

#cities-tab li a {
  padding: 4px 10px;
  border-radius: 20px;
  border: 1px solid #dee2e6 !important;
  font-size: 12px;
}

#section-2 .nav-tabs .nav-link {
  color: #a1a1a1 !important;
  background-color: #fff !important;
  border: none;
  width: 100%;
  display: block;
}

#cities-tab li:last-of-type a {
  border-radius: 0 20px 20px 0;
}

#cities-tab li a {
  padding: 4px 10px;
  border-radius: 20px;
  border: 1px solid #dee2e6 !important;
  font-size: 12px;
}

.nav-tabs .nav-link {
  color: #00aae5;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.audience-data-sec {
  display: flex;
  flex-direction: column;
}

.audience-data {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 15px;
  min-height: 132px;
  border-radius: 4px;
}

.audience-data-value.reach {
  margin-bottom: 0;
  line-height: 50px;
  margin-top: auto;
  align-self: center;
}

.audience-data-value {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 50px;
  font-weight: 800;
}

.audience-data .audience-data-exact-value {
  align-self: center;
}

.media-post-detail.stories .card-posts-info ul:only-of-type {
  width: 100%;
  border: none;
  flex-direction: row;
  padding-right: 0;
}

.media-post-detail.stories .card-posts-info ul li {
  width: 50%;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  height: 320px;
}

.card-posts-image img {
  object-fit: cover;
}

.card-posts-image,
.card-posts-image img {
  border-radius: 16px 16px 0 0;
  background: #fff;
}

.card-posts-info {
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  border-radius: 0 0 16px 16px;
}

.audience-data small {
  margin-top: auto;
}

.card-posts-info ul {
  padding-left: 0;
  list-style: none;
  font-size: 13px;
  margin-bottom: 0;
  position: relative;
  width: calc(43% - 10px);
  display: flex;
  flex-direction: column;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.card-posts-info ul li {
  display: flex;
  padding-bottom: 5px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card-posts-info ul li strong {
  font-weight: 400;
  // flex: 0 0 54%;
}

.card-posts-info ul:first-of-type {
  padding-right: 15px;
  border-right: 1px solid #ddd;
  width: calc(57% - 0px);
}

#cities-tab {
  border-bottom: none;
}

.jce-data {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}

.mf-ratio {
  border-right: 1px solid #dee2e6;
}

.ad-ratio {
  text-align: right;
}

.ratio-sec {
  padding-bottom: 40px;
}

.icon-male:before {
  content: "\e905";
}

.icon-female:before {
  content: "\e904";
}

.cities-tab-sec .css-13xfq8m-MuiTabPanel-root {
  padding: 0px;
}

// .MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer {
//   position: unset;
// }

// .css-1aquho2-MuiTabs-indicator {
//   position: unset;
// }

.cities-tab-sec .MuiTabs-flexContainer {
  display: flex;
  // justify-content: space-between;
}

.city-section {
  margin-top: 14px;
  margin-right: auto;
}

.ind-row-tab {
  padding-top: 0px;
}

//Progress-Bar

.progress_section {
  width: 100%;
  max-width: 600px;
  border-radius: 7px;
  // padding: 1rem 1rem;
  margin: 0 auto;

  .task-progress {
    margin: 0.2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .task-progress p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4c4c4c;
    margin-bottom: 5px;
    font-size: 14px;
  }

  span {
    color: #4c4c4c;
    font-weight: 600;
    font-size: 14px;
  }

  .progress {
    // width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 10px;
    height: 0.5rem;
  }

  .city {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .percentage {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-right: 5px;
  }

  .progress::-webkit-progress-bar {
    background-color: #e9ecef;
  }

  .progress::-webkit-progress-bar,
  .progress::-webkit-progress-value {
    border-radius: 10px;
  }

  .progress::-moz-progress-bar {
    border-radius: 10px;
  }

  .progress1::-webkit-progress-value {
    background: #ff7a53;
  }

  .progress2::-webkit-progress-value {
    background: #ff7a53;
  }

  .progress3::-webkit-progress-value {
    background: #ff7a53;
  }

  .progress4::-webkit-progress-value {
    background: #ff7a53;
  }

  .progress5::-webkit-progress-value {
    background: #ff7a53;
  }
}

//Progress-2

@import url(https://fonts.googleapis.com/css?family=Roboto);
// Progress bg colours
$pg-default-bg: #eee;
// Progress bar bg colours
$bar-default-bg: #ff7a53;
$bar-info-bg: #ff7a53;
$bar-danger-bg: #ff7a53;
$bar-success-bg: #ff7a53;
$bar-warning-bg: #ff7a53;

// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin browser_prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

// KEYFRAMES
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

// ANIMATION
@mixin animation($str) {
  @include browser_prefix("animation", $str);
}

@mixin cssProgressGlowActive($name, $color) {
  @include keyframes($name) {

    0%,
    100% {
      box-shadow: 5px 0px 15px 0px #{$color};
    }

    45% {
      box-shadow: 1px 0px 4px 0px #{$color};
    }
  }
}

main {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;

  .main-content {
    margin: 0 8px;
    padding: 60px 0;
  }

  .examples {
    display: block;
    margin-bottom: 65px;
    padding: 2.8rem 3%;
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.157);

    &:last-child {
      margin-bottom: 20px;
    }

    h3 {
      color: #444;
      margin: 0;
      padding: 0 0 20px 0;
    }
  }
}

/* -----------------------------------------------------
	CSS Progress Bars
-------------------------------------------------------- */
.cssProgress {
  margin: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;

  .progress1,
  .progress2,
  .progress3 {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: "Roboto", sans-serif;
  }

  .cssProgress-bar {
    display: block;
    float: left;
    width: 0%;
    height: 100%;
    background: $bar-default-bg;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
    transition: width 0.8s ease-in-out;
  }

  .cssProgress-label {
    position: absolute;
    overflow: hidden;
    left: 0px;
    right: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.7em;
    text-align: center;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
  }

  .cssProgress-info {
    background-color: $bar-info-bg !important;
  }

  .cssProgress-danger {
    background-color: $bar-danger-bg !important;
  }

  .cssProgress-success {
    background-color: $bar-success-bg !important;
  }

  .cssProgress-warning {
    background-color: $bar-warning-bg !important;
  }

  .cssProgress-right {
    float: right !important;
  }

  .cssProgress-label-left {
    margin-left: 10px;
    text-align: left !important;
  }

  .cssProgress-label-right {
    margin-right: 10px;
    text-align: right !important;
  }

  .cssProgress-label2 {
    display: block;
    margin: 2px 0;
    padding: 0 8px;
    font-size: 0.8em;

    &.cssProgress-label2-right {
      text-align: right;
    }

    &.cssProgress-label2-center {
      text-align: center;
    }
  }

  .cssProgress-stripes,
  .cssProgress-active,
  .cssProgress-active-right {
    background-image: linear-gradient(-45deg,
        rgba(255, 255, 255, 0.125) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.125) 50%,
        rgba(255, 255, 255, 0.125) 75%,
        transparent 75%,
        transparent);
    background-size: 35px 35px;
  }

  .cssProgress-active {
    @include animation("cssProgressActive 2s linear infinite");
  }

  .cssProgress-active-right {
    @include animation("cssProgressActiveRight 2s linear infinite");
  }

  @include keyframes("cssProgressActive") {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 35px 35px;
    }
  }

  @include keyframes("cssProgressActiveRight") {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -35px -35px;
    }
  }
}

/* -----------------------------------------------------
	Progress Bar 1
-------------------------------------------------------- */
.progress1 {
  background-color: $pg-default-bg;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);

  .cssProgress-bar {
    height: 18px;
  }

  .cssProgress-label {
    line-height: 18px;
  }
}

/* -----------------------------------------------------
	Progress Bar 2
-------------------------------------------------------- */
.progress2 {
  background-color: $pg-default-bg;
  border-radius: 9px;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);

  .cssProgress-bar {
    height: 0.5rem;
    border-radius: 10px;
  }

  .cssProgress-label {
    line-height: 18px;
  }
}

/* -----------------------------------------------------
	Progress Bar 3
-------------------------------------------------------- */
.progress3 {
  width: auto !important;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  .cssProgress-bar {
    height: 16px;
    border-radius: 3px;
  }

  .cssProgress-label {
    line-height: 16px;
  }
}

/* -----------------------------------------------------
	Progress Bar 4
-------------------------------------------------------- */
.progress4 {
  position: relative;
  width: 100%;
  background-color: $pg-default-bg;

  &.cssProgress-bg {
    background-color: #ff7a53 !important;
  }

  &.cssProgress-bg-info {
    background-color: #ff7a53 !important;
  }

  &.cssProgress-bg-danger {
    background-color: #ff7a53 !important;
  }

  &.cssProgress-bg-success {
    background-color: #ff7a53 !important;
  }

  &.cssProgress-bg-warning {
    background-color: #ff7a53 !important;
  }

  .cssProgress-bar {
    display: block;
    float: none;
    width: 0%;
    height: 4px;
    background: $bar-default-bg;

    &.cssProgress-lg {
      height: 6px;
    }

    &.cssProgress-2x {
      height: 8px;
    }

    &.cssProgress-3x {
      height: 10px;
    }

    &.cssProgress-4x {
      height: 12px;
    }

    &.cssProgress-5x {
      height: 14px;
    }

    &.cssProgress-glow {
      box-shadow: 5px 0px 15px 0px #3798d9;

      &.cssProgress-info {
        box-shadow: 5px 0px 15px 0px $bar-info-bg;
      }

      &.cssProgress-danger {
        box-shadow: 5px 0px 15px 0px $bar-danger-bg;
      }

      &.cssProgress-success {
        box-shadow: 5px 0px 15px 0px $bar-success-bg;
      }

      &.cssProgress-warning {
        box-shadow: 5px 0px 15px 0px $bar-warning-bg;
      }
    }

    &.cssProgress-glow-active {
      @include animation("cssProgressGlowActive1 3s linear infinite");

      &.cssProgress-info {
        @include animation("cssProgressGlowActive2 3s linear infinite");
      }

      &.cssProgress-danger {
        @include animation("cssProgressGlowActive3 3s linear infinite");
      }

      &.cssProgress-success {
        @include animation("cssProgressGlowActive4 3s linear infinite");
      }

      &.cssProgress-warning {
        @include animation("cssProgressGlowActive5 3s linear infinite");
      }
    }
  }

  @include cssProgressGlowActive("cssProgressGlowActive1", "#3798D9");
  @include cssProgressGlowActive("cssProgressGlowActive2", $bar-info-bg);
  @include cssProgressGlowActive("cssProgressGlowActive3", $bar-danger-bg);
  @include cssProgressGlowActive("cssProgressGlowActive4", $bar-success-bg);
  @include cssProgressGlowActive("cssProgressGlowActive5", $bar-warning-bg);
}

.cities {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.percent-bar {
  min-width: 60px;
}

.progress-bars {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
  overflow: hidden;
  width: 50%;
}

.p-span {
  color: #4c4c4c;
  font-weight: 600;
  font-size: 14px;
}