.common-dialog {
    .common-dialog_header {
        padding: 0;
        margin-bottom: 25px;
    }

    .MuiPaper-root {
        padding: 16px;
    }

    .common-dialog_footer {
        padding: 0;
    }
}