.curated-list-sec {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  justify-content: space-between;

  .curated-list {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;

    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #2b2b2b;
    overflow: hidden;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    flex-wrap: wrap;
    background-position: center center;
    background-size: cover;
    flex: 0 0 150px;

    @media only screen and (max-width:767px) {
      flex: 0 0 calc(25% - 10px);
    }

    @media only screen and (max-width:575px) {
      flex: 0 0 calc(50% - 5px);

    }

    &:nth-child(2n) {
      @media only screen and (max-width:575px) {
        margin-right: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }


    a {
      color: $color-white;
      display: flex;
      width: 150px;
      height: 100px;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      position: relative;
      z-index: 0;
      transform: scale(1);
      padding: 10px 20px;
      text-align: center;
      @extend %cubic-transition-5s;

      @media only screen and (max-width:767px) {
        width: 100%;
      }

      &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.5);
        z-index: -1;
      }
    }

    &:hover {

      a {
        transform: scale(1.1);
      }
    }

  }
}

.filter-dropdown-sec {
  position: relative;
}

.filter-dropdown {
  height: 40px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 16.5px 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);

  &:after {
    position: absolute;
    right: 15px;
    border-width: 6px;
    width: 0;
    height: 0;
    content: "";
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
    top: 18px;


  }

  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
  max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  .filter-dropdown-text {
    transform: translate(0px, 9px) scale(1);
    position: absolute;
    left: 0;
    top: 0;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: #fff;
    padding: 0 10px;

  }

  &.active {
    border-color: #1976d2;

    .filter-dropdown-text {
      transform: translate(0px, -9px) scale(0.75);
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      color: #1976d2;
    }

    &:after {
      border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
      top: 11px;
    }
  }

}

.search-container-content {
  position: relative;
  z-index: 2;
  max-width: calc(100% - 0px);
  margin-right: 0px;

  .btn-search {
    position: absolute;
    right: 0;
    padding: 10px 16px;
  }

  .form-control {
    font-size: 14px;
    height: 46px;
    border-radius: 25px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .search-plt-type-icon {
    position: absolute;
    top: 8px;
    left: 20px;
    width: 24px;

    &.icon-youtube {
      top: 10px;
      color: #f00;
    }

  }

}

.search-suggestion {
  width: calc(100% - 0px);
  background-color: white;
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 25px;
  z-index: -1;
  padding-top: 20px;
  padding-bottom: 15px;
  border: 1px solid #dee2e6;
  border-radius: 0 0 4px 4px;
  box-shadow: 1px 1px 4px #dee2e6;

  >ul {
    padding-right: 17px;
  }

  .insta-search-list {
    list-style: none;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;

    p {
      margin-bottom: 0;
    }

    .insta-search-username {
      text-decoration: none;
      color: black;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.compare-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0;

  .tools {
    // position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // background-color: #00aae5;
    position: relative;
    height: 110px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 0px;
    z-index: 9999;
    background-color: #fff;
    padding: 15px 15px 20px;

    .image-section {
      position: absolute;
      top: -40px;
      display: flex;

      .compare-img {
        img {
          height: 80px;
          width: 80px;
          display: flex;
          border-radius: 50%;
          margin: 0px 10px;
          border: 1px solid #dee2e6;
          box-sizing: border-box;
          background-color: #fff;

          cursor: pointer;
        }
      }
    }

    .button-section {
      overflow: hidden;

      .btn {
        margin: 0px 10px;
      }
    }
  }
}


.filter-dropdown-menu {
  padding: 10px 15px;
  border: 1px solid #ced4da;
  background: #fff;
  border-radius: 4px;
  display: none;
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 50px;
  width: calc(100% - 16px);

  &.active {
    display: block;
    border-top: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #1976d2;
  }
}

.form-filter {
  margin-bottom: 20px;

  .btn {
    height: 40px;
    line-height: 1;
  }
}


.infl-info-sec {
  .infl-count {
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
  }
}

.infl-category-sec {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;


  .infl-category {
    display: flex;
    flex-direction: column;
    background: #eee;
    border-right: 1px solid #ddd;
    text-align: center;
    flex: 1;
    cursor: pointer;

    &:last-of-type {
      border-right: none;
    }

    &.active {
      background-color: #00aae5;
      color: #fff;
    }
  }

  .infl-category-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #d7d7d7;
    font-weight: 600;

    @media only screen and (min-width:1200px) {

      padding: 10px 30px;
    }

    span {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .infl-category-count {
    font-size: 12px;
    padding: 5px 20px 10px 30px;
  }
}

.infl-action-btns {
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    color: $color-primary;
    margin-right: 10px;
  }

  .helper-text {
    color: #2b2b2b;
    font-size: 11px;
  }

  span {
    margin-right: 10px;
  }
}

.filter-dropdown-menu .MuiTypography-root {
  font-size: 13px;
}

.filter-dropdown-menu .MuiFormControlLabel-root {
  align-items: flex-start;
}

.filter-tab-panel {
  margin-bottom: 150px;
}

.discover-platform-tab {
  .MuiTab-root {
    font-size: $font-size-body;

    svg[data-testid="YouTubeIcon"] {
      fill: $color-red;
    }

    &.Mui-selected {
      color: $color-black;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-black;
  }
}

.list-section {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    height: 300px;
    overflow-y: auto;

    li {
      font-size: 16px;
      padding: 10px;
      margin: 10px 0px;
      cursor: pointer;
      border: 1px solid $color-black-7;
      border-radius: 4px;
      background-color: $color-white;
      display: flex;
      align-items: center;

      &:hover {
        border-color: $color-black;

        .list-section-icon {
          color: #2b2b2b;

          svg path {
            fill: #2b2b2b;
          }
        }
      }
    }

    .selected-list {
      background-color: $color-black;
      color: $color-white;

      svg#instagram path {
        fill: $color-white;
      }
    }
  }
}