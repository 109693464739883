$activeColor: #00AAE5;


.campaign_influencer_main_container {

    .button_group {
        .campaign_influencer_buttons {
            display: flex;
            justify-content: flex-end;

            .btn {
                margin-left: 10px;
            }
        }
    }


    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        cursor: pointer;

        .tabs_formControl {
            display: block;
            margin-bottom: 20px;

            li {
                flex: 1;
                border-radius: $border-radius-card;
                border: 1px solid $border-color-card;
                padding: 20px;
                display: flex;
                flex-direction: column;


                .MuiFormControlLabel-label {
                    font-weight: bold;
                }

                .MuiFormControlLabel-root {
                    margin: 0;
                    margin-bottom: 4px;
                }

                .tabContent_formControl {
                    font-size: $font-size-sml;
                    padding-left: 32px;
                }
            }
        }
    }

    // .campaign_influencer_tab_container {
    //     border: 1px solid black;
    //     padding: 5px 20px;
    // }
}

// ._campaign_influencer_tab {

//     .Mui-selected {
//         background-color: $activeColor;
//         color: white;
//         transition: .3s ease-in;
//         position: relative;
//     // &:before{
//     //     content: 'Active';
//     //     position: absolute;
//     //     right: 10px;
//     //     border: 1px solid white;
//     //     padding: 5px 10px;
//     //     border-radius: 2px;

//     // }
//     }

//     .tabs {
//         background-color: lightgray;
//         color: black;
//     }

//     width: 100%;
//     // border: 1px solid black;
//     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
//     padding: 0px;
//     margin: 10px;
// }