$activeColor: #00AAE5;

.influencer_type_component_container {
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;

    .influencer_type_tabs {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid gray;

        li {
            flex: 1;
            padding: 10px;
            background-color: lightgray;
            text-align: center;
            border-right: 1px solid gray;

            span {
                font-size: 14px;
            }

            .tab_image {
                height: 50px;
            }
        }

        .active {
            color: white;
            background-color: $activeColor;
        }
    }


    .select_deliverables_main_container {
        ul {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid lightgray;
                // padding: 10px;


                &:last-child {
                    border: none;
                }

            }
        }
    }

    .counter_main {
        display: flex;
        align-items: center;
        justify-content: center;

        input[type=text] {
            width: 28px !important;
            height: 28px !important;
            border: 1px solid #dee2e6;
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            margin: 0px 5px;
        }

        .count_button {
            width: 28px;
            height: 28px;
            background: #f2f2f2;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block;
            line-height: 26px;
            padding: 0;
            vertical-align: middle;
            border: 1px solid #ddd;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            user-select: none;
            -webkit-user-select: none;
        }
    }

    @media only screen and (max-width: 600px) {
        .influencer_type_tabs {
            flex-direction: column;
            transition: 2s ease-in-out;

            li {
                display: flex;
                align-items: center;

                span {
                    margin: 0px 10px;
                }
            }
        }
    }

}

.influencer_type_component_container {
    .MuiSelect-select {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 14px;
        font-size: 14px;
    }
}