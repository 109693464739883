.nav {
    &.sub-tabs {
        // margin-bottom: 25px;
        min-height: 46px;
        // margin-top: 10px;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        &:after,
        &:before {
            position: absolute;
            content: "";
            right: 0;
            width: 35px;
            height: 27px;
            background: linear-gradient(to left, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%) !important;
            z-index: 0;
        }

        &:before {
            right: auto;
            left: -10px;
            background: linear-gradient(to right, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%) !important;
            z-index: 1;
        }

        .nav-link {
            border-bottom: 1px solid transparent;
            // align-items: flex-start;

            &.active {
                border-bottom: 1px solid $color-black;
                color: $color-black;
            }

            &:hover {
                color: $color-black;
                border-color: $color-black-7;
            }
        }

        .MuiTabs-scrollButtons.MuiTabScrollButton-root {
            margin-right: 1px;
            margin-left: 1px;
            border: 1px solid $color-black;
            z-index: 1;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            transition: box-shadow 0.3s, transform cubic-bezier(0.2, 0, 0, 1);
            background-color: $color-white;

            &.Mui-disabled {
                opacity: 0;
            }

            &:hover {
                box-shadow: 0 0 5px #ddd;
                transform: scale(1.04);
                transition: box-shadow 0.3s, transform cubic-bezier(0.2, 0, 0, 1);
            }
        }

    }

    &.no-scrollbar {

        &:before,
        &:after {
            display: none;
        }
    }

    &.tab-rounded {
        .nav-link {
            border-radius: 1.25rem;
            padding: 0.25rem 1rem;
            border-bottom: none;
            align-items: center;
            margin-right: 0.63rem;
            margin-left: 0.63rem;

            &.active,
            &.Mui-selected,
            &:hover {
                color: $color-white;
                // font-weight: 600;
                // border-bottom: 1px solid $color-black;
                opacity: 1;
                background-color: $color-black;
            }
        }
    }

    &.w-bg {
        .nav-link {
            background-color: #ECECEC;
            flex: 1 0 auto;

            &:hover {
                color: $color-white;
            }
        }
    }
}


.nav {
    .nav-link {
        color: $color-body;
        border-bottom: 1px solid transparent;

        &:hover,
        &.active {
            // color: $color-primary;
            // border-bottom-color: $color-primary;

        }


    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
    }

    &.sub-tabs {
        ul {
            display: flex;
            // border-bottom: 1px solid $border-color-card;
            width: 100%;
            list-style: none;


        }
    }
}

.tab-underline {
    // border-bottom: 1px solid $border-color-card;
    margin-bottom: 0.94rem;
    align-items: center;

    .MuiTabs-flexContainer {
        .tab-underline-menu {
            // margin-bottom: -2px;
            font-family: $font-family-body;
            text-transform: none;
            font-size: $font-size-sml;
            letter-spacing: 0;
            line-height: 20px;
            opacity: 0.6;

            &.active,
            &.Mui-selected {
                color: $color-black;
                font-weight: 600;
                border-bottom: 1px solid $color-black;
                opacity: 1;
            }

            // &:first-child {
            //     padding-left: 0;
            // }
        }
    }

    .tab-underline-menu {
        // margin-bottom: -2px;
        font-family: $font-family-body;
        text-transform: none;
        font-size: $font-size-body;
        letter-spacing: 0;

        &.Mui-selected {
            color: $color-primary;
            font-weight: 700;
        }

        // &:first-child {
        //     padding-left: 0;
        // }
        &:hover {
            border-bottom: 1px solid rgba(0, 0, 0, 0.7);
            color: #000;
            opacity: 1;
        }
    }

    .MuiTabs-indicator {
        background-color: $color-primary;
        bottom: -1px;
    }
}

.common-tab-sec {
    button {
        font-family: "Open sans";
        text-transform: none;

        &.Mui-selected {
            color: #00aae5;
        }

        .MuiTabs-indicator {
            background: #00aae5;
        }
    }
}

.MuiTabs-root.tab-underline {
    .MuiButtonBase-root-MuiTabScrollButton-root {
        border-radius: 20px;
        height: 30px;
        border: 1px solid #000;
        width: 30px;

        &:hover {
            background-color: #000;
        }
    }
}