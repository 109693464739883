$color-body: #2b2b2b;

$color-primary: #00aae5;

.products-container {
    flex: 99999;
}

.product-card-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 80px;

    .card-container {
        border-radius: 8px;
        border: 1px solid #dee2e6;
        box-shadow: none;
        background: #f2f2f2;
        position: relative;

        .product-card-image {

            .MuiCardMedia-root {
                height: 220px;
                border-radius: 0 0 8px 8px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .product-card-content {
            h3 {
                font-size: 20px;
                font-weight: 700;

                .card-name-span {
                    &:hover {
                        cursor: pointer;
                        color: #00aae5;
                    }
                }
            }

            .product-card-desc {
                padding-bottom: 30px;
                font-size: 14px;
                min-height: 100px;
            }
        }
    }
}