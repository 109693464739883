$backGroundColor: #E6E6E6;
$activeColor: #00aae5;

.plan_campaigns_main {
    .sidebar {
        ul {
            list-style: none;
            margin: 0px 0px 0px 5px;
            padding: 0px;

            li {
                padding: 5px 10px;
                cursor: pointer;
            }

            .active {
                background-color: $backGroundColor;
                transition: .4s ease-in-out;
            }
        }
    }

    .main-content-sec {
        padding: 0px;
        min-height: 100vh;



        .header {
            ul {
                list-style: none;
                background-color: $backGroundColor;
                margin: 0px;
                padding: 20px 0px;

                li {
                    display: inline-block;
                    margin: 0px 20px;
                    font-weight: bold;
                    font-size: large;
                    cursor: pointer;

                    .navLink {
                        text-decoration: none;
                        color: black;

                    }

                    .active {
                        color: $activeColor;
                    }
                }
            }
        }

    }
}

.page_title {
    display: flex;
    align-items: flex-start;

    .social-icon {
        margin-right: 8px;
    }
}