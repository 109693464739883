.card-wrapper {
    border: 1px solid $border-color-card;
    padding: 15px;
    background-color: $color-white;
    border-radius: $border-radius-card;
    margin-bottom: 15px;

    .card-title {
        font-size: $font-size-sml;
        margin-bottom: 10px;
        display: block;
    }
}