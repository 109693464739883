.common-table {
    font-family: $font-family-body;
    padding-left: 1px;

    &-header {
        tr {

            th {
                color: $color-white;
                padding-top: 0.38rem;
                padding-bottom: 0.38rem;
                font-size: $font-size-sml;
                text-align: left;
                border-bottom-color: $color-black-7;
                background-color: $color-black;
                font-family: $font-family-body;

                .MuiTableSortLabel-root {

                    &:hover,
                    &.Mui-active {
                        color: $color-white;
                    }
                }

                // border-bottom: none;
            }
        }
    }

    &-body {

        font-family: $font-family-body;

        .MuiTableCell-root {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom-color: $color-black-7;
        }

        .MuiTableCell-body {
            .Mui-disabled {
                background: #ededed;
                border-radius: 0.5rem;

                .MuiOutlinedInput-notchedOutline {
                    border-color: #ededed !important;
                }
            }

            .MuiInputBase-root {
                border-radius: 0.5rem;

            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #6a6a6a !important;
            }
        }

        tr {
            td {
                &:first-of-type {
                    border-left: 1px solid $color-black-7;
                }
            }
        }

        .table_cell {
            // display: flex;
            // min-height: 45px;
            // align-items: center;
            border-left: 1px solid $color-black-7;
            font-family: $font-family-body;
        }

        &:last-of-type {
            .table_cell {
                border-left: 1px solid $color-black-7;
                border-bottom: 1px solid $color-black-7;
            }
        }

        tr {
            td {
                font-family: $font-family-body;

                &:last-of-type {
                    border-right: 1px solid $color-black-7;
                }
            }

            &:last-of-type {
                td {
                    border-bottom: 1px solid $color-black-7
                }
            }
        }
    }

    &-pagination {}

    // thead th {
    //     background-color: #e1e5ec;
    //     color: #9399a4;
    //     border-color: #eaecf0;
    //     padding-top: 10px;
    //     // max-width: 80px;
    //     padding-bottom: 10px;
    //     line-height: normal;
    //     font-size: 14px;
    //     text-align: left;
    // }

    tbody td {
        background-color: #fff;
    }

    &.common-table-densed {
        .MuiTableCell-root {
            padding: 0.38rem;
        }
    }
}


.table-deliverable {
    box-shadow: none;
    border: 1px solid $border-color-card ;

    td {
        ul {
            margin-bottom: 0;
            padding-left: 1.06rem;

            li {
                padding-bottom: 0.63rem;
            }
        }
    }

    th {
        font-family: Open sans !important;
    }

    tbody {
        th {
            width: 210px;
            vertical-align: top;
            border-right: 1px solid $border-color-card !important;
        }
    }
}

.common-pagination {

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows,
    .MuiIconButton-root,
    .MuiTablePagination-select {
        color: $color-body;
    }
}