.no-spacing.switch-account {
    .main-content-sec {
        margin-top: 100px;
    }
}

._switch_container_fluid {
    // background-color: rgba(0, 0, 0, 0.8);
    margin-top: 100px;

    .no-spacing.switch-account & {
        height: auto;
        margin-bottom: 50px;

        @include breakpoint(small) {
            height: auto;
        }


    }

    @include breakpoint(small) {
        margin-top: 0px;
    }

    ._switch_main_row {

        @include breakpoint(small) {
            // height: calc(100vh - 100px);
        }

        align-items: center;

        ._switch_main_col {
            // min-height: 100vh;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // flex-direction: column;
            display: flex;
            background: #fff;
            // padding: 15px;
            border-radius: 14px;
            margin: 0;


            ._switch_details_cards {

                margin: 10px 0px;
                padding: 20px;
                display: flex;
                align-items: center;
                border: 1px solid $color-black-7;
                background-color: $color-white;
                cursor: pointer;
                border-radius: 14px;
                transition: transform cubic-bezier(.48, .35, .67, .79) 0.15s, border-color cubic-bezier(.48, .35, .67, .79) 0.15s, background-color cubic-bezier(.48, .35, .67, .79) 0.15s;

                ._switchDetails {
                    width: 300px;
                }

                ._switchImage {
                    height: 65px;
                    width: 65px;
                    padding: 0px;
                    margin: 0px;
                    margin-right: 0px;
                    border: 1px solid #dee2e6;
                    border-radius: 50%;
                    margin-right: 10px;
                    align-self: flex-start;
                }

                &:active {
                    transform: scale(0.975);
                }

                &.active {

                    background-color: $color-offwhite;
                    border: 2px solid;
                }

                &:hover {
                    border-color: $color-black;
                }
            }
        }

    }

    ._switch-acc-info {
        display: block;
    }

    ._switch_account_name {
        font-weight: 600;
    }

    ._switch_account_type {
        padding-bottom: 10px;
    }

    ._switch_full_name,
    ._switch_account_type,
    ._switch_email_id {
        font-size: $font-size-sml;
    }
}

@include mobileBreakpoint(mobile) {

    // ._switch_container_fluid ._switch_container ._switch_main_row {
    //     margin: 0;
    // }
    ._switch_container_fluid ._switch_container ._switch_main_row {
        ._switch_main_col.row {
            margin: 0;
            padding: 10px 5px;
        }
    }
}

._switch_details_cards .icon-check {
    position: absolute;
    right: 10px;
    top: 10px;
    color: green;
    font-size: 30px;

}

._switch_details_cards {
    position: relative;
}