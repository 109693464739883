    $borderSize: 36px;
    $borderRadius: 9px;
    $background-color: #00AAE5;
    $boxSize: 170px;


    .campaign_product_main_container {
        padding: 0px;

        .product_image_section {
            display: flex;
            flex-direction: column;

            .product_image {
                height: 200px;
                width: 200px;
                margin-bottom: 10px;
            }
        }

        // .row {
        //     margin: 10px;
        // }

        .product_add {
            display: flex;

            .add_button {
                margin-left: 10px;
                align-self: flex-start;
            }

            .remove_button {
                margin-left: 10px;
            }
        }

        .create_campaign_upload_photo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: lightgray;
            cursor: pointer;

            .icon {
                color: $background-color;
            }

            .upload_text {
                color: $background-color;
            }
        }

        .campaign_product_buttons {
            display: flex;
            justify-content: flex-end;

            .btn {
                margin-left: 10px;
            }
        }


    }

    .list_ul {
        margin-top: 20px;

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            li {
                // border: 2px solid black;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                padding-bottom: 10px;
                font-weight: bolder;
                border-bottom: 1px solid $border-color-card;
                color: $color-body;
                font-size: $font-size-sml;

                .close_button {
                    color: $color-cancel;
                    cursor: pointer;
                }

                &:last-of-type:not(:only-of-type) {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }
    }