.filter-sec {
    &_row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee2e6;

        >strong {
            margin-bottom: 5px;
            min-width: 200px;
        }

    }

    &_row_right {
        flex: 99999;
        display: flex;
        flex-wrap: wrap;
    }

    &_row_list {
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;

        li {
            list-style: none;
            margin-right: 10px;
            margin-bottom: 10px;

            .MuiChip-root {
                border-color: $color-primary;
            }

            &:hover {
                .MuiChip-root {
                    background-color: $color-primary;
                    color: $color-white;
                }
            }
        }
    }


}

.custom-range {
    .MuiFormControl-root {
        .MuiInputBase-input {
            padding-top: 5.5px;
            padding-bottom: 5.5px;
            font-size: 14px;
        }

        margin: 0;
    }

    .MuiInputLabel-root {
        transform: translate(14px, 4px) scale(1);

        &.MuiInputLabel-shrink {
            transform: translate(14px, -9px) scale(0.75);
        }
    }

}

.filter-tab-panel {
    .MuiBox-root {
        padding: 0 0 20px;
        max-width: 100%;
    }
}