body {
  font-size: $font-size-body;
  font-family: $font-family-body;
  // background-color: $color-page-background;
  letter-spacing: 0 !important;
  color: $color-body;

  &.common-signup-page {
    background: url("../../images/banner-bg.png");

    // &.login-page {
    //   background-image: none;
    // }
  }
}

.common-signup-page .main-content-sec {
  margin-top: 0;
}

.error {
  color: $errorColor;
  font-size: $font-size-xsml;
  display: block;
}

.main-content-sec {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  padding-bottom: 100px;

  .no-spacing & {
    margin-top: 0;
  }
}

.container-fluid {
  max-width: 1920px;
}

.w-100 {
  width: $width-full;
}

ol,
ul {
  padding-left: 17px;
}


.common-bg {
  min-height: inherit;
  height: 100vh;
  // background: url("../../images/banner-bg.png");
  position: relative;
  overflow: hidden;

  @include breakpoint(small) {
    min-height: 100vh;
    height: inherit;
  }

  &:after {
    content: "";
    position: absolute;
    background: url("../../images/homepage-banner-outline-icon.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60% auto;
    z-index: -1;
    top: 0;
    right: -12%;
  }
}

.container {
  @include breakpoint(xsml) {
    max-width: 100%;
  }

  @include breakpoint(large) {
    max-width: $container;
  }

  @include breakpoint(xlarge) {
    max-width: $container-large;
  }


}

.social-icon {
  svg {
    width: 24px;
    height: 23px;
  }
}

.MuiButtonBase-root,
.MuiButton-root,
.MuiMenuItem-root,
.MuiFormLabel-root,
.MuiTypography-root,
.MuiFormControl-root,
.MuiInputBase-root,
.MuiAutocomplete-root {
  font-family: $font-family-body !important;
  letter-spacing: 0 !important;
}

.fs-20 {
  font-size: $font-size--1_25rem !important;
}

.fs-18 {
  font-size: $font-size-large !important;
}

.fs-14 {
  font-size: $font-size-sml !important;
}

.fs-12 {
  font-size: $font-size-xsml !important;
}

.editor_components .ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
}


.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.terms {
  ol li {
    padding-bottom: 5px;
    font-size: 14px;
  }
}

.input-w-btn {
  border: 1px solid #000;
  border-radius: 8px;
}

.input-w-btn>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none !important;

  border-left: 1px solid $color-body !important;
  color: $color-body !important;
  min-width: 150px !important;
}

.input-w-btn .MuiOutlinedInput-notchedOutline {
  border: none;
}

.input-w-btn {
  &.fixed-right {
    position: relative;
    border: none;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $border-color-card;
      padding-right: 150px;
    }

    .MuiInputBase-input {
      padding-right: 160px;
    }

    .btn {
      position: absolute;
      right: 0;
      background-color: $color-primary;
      color: $color-white !important;
      height: 100%;
      border-color: $border-color-card !important;
    }

  }
}



.MuiTabs-root {
  .MuiTabs-flexContainer {
    .MuiTab-root {
      // color: $color-black;
      font-family: $font-family-body;
      text-transform: none;
      font-weight: normal;

      &.Mui-selected {
        // color: $color-primary;

        // svg path {
        //   fill: #00aae5;
        // }
      }
    }
  }

  // .MuiTabs-indicator {
  //   background-color: $color-primary;
  // }

  .MuiTabs-scrollButtons {
    &.Mui-disabled {
      opacity: 0.3;
    }
  }
}

.icon-instagram {
  #instagram {
    width: 100%;
    height: 100%;
    // vertical-align: top;
  }
}

.icon-youtube {
  color: #f00;
}

.text-link {
  color: $color-body;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.MuiFormHelperText-root {
  font-family: $font-family-body !important;
  letter-spacing: normal !important;
}

.color-blk-5d {
  color: $color-black-5d !important
}

svg {
  &[data-testid="YouTubeIcon"] {
    color: $color-red;
  }
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 24
}
