.stepper{
    width: 421px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;

    span{
        font-size: 13px;
        line-height: 17.7px;
        font-weight: 400;
        color: #8c8c8c;
    }

    .separater{
        width: 77px;
        border: 1px solid #d7d7d7;
        margin: 0px 10px;
    }

    .active{
        color: #000000;
    }
}