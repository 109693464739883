.sign-up-container {

    // &:after{
    //     content: "";
    //     position: absolute;
    //     background: url("../../images/homepage-banner-outline-icon.png");
    //     height: 100%;
    //     width: 100%;
    //     background-repeat: no-repeat;
    //     background-position: right bottom;
    //     background-size: 60% auto;
    //     z-index: -1;
    //     top: 0;
    //     right: -12%;
    // }
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;

        @media only screen and (max-width:991px) {
            max-width: 100%;
        }
    }

    // min-height:100vh;
    // background: url("../../images/banner-bg.png");
    // position: relative;
    // overflow: hidden;

    .signup-title {
        font-size: 28px;
        color: $color-primary;
        padding-bottom: 20px;

        p {
            margin: 0px;

            @include mobileBreakpoint(mobile) {
                text-align: center;
                line-height: 1.2;
            }
        }
    }

    .sign-up-buttons {


        ul {
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            list-style: none;

            @include mobileBreakpoint(mobile) {
                flex-direction: column;
                margin: 0 auto;
                max-width: 200px;
            }

            li {
                margin-right: 20px;

                @media only screen and (max-width:991px) {
                    margin-right: 6px;
                }

                @include mobileBreakpoint(mobile) {
                    margin-bottom: 10px;
                }

                a {
                    font-size: 22px;
                    background-color: $color-white;

                    @include mobileBreakpoint(mobile) {
                        font-size: 20px;
                        padding-left: 25px;
                        padding-right: 25px;
                        width: 100%;
                    }

                }
            }
        }
    }

    @include breakpoint(small) {
        .sign-up-buttons {
            ul {

                li {
                    display: flex;


                }
            }
        }
    }
}