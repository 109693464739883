$color-primary: #00aae5 !default;

.plan-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-color-card;
    border-radius: 8px;
    padding-bottom: 20px;

    &.brand {
        flex-direction: row;
    }
}

.days-span {
    color: $color-black-7;
    // padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    font-weight: 500;
}

.description-span {
    // padding: 10px 0px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: $font-size-sml;
}

.price-span {
    // padding: 10px 0px;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
}

.plan-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan-title {
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: $color-black;
    color: $color-white;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
    font-size: $font-size-large;
    border-bottom: 1px solid $border-color-card;
}

.subs-sec .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center;
    max-width: 960px;
    padding: 0;
}

.subs-sec .container.brand {
    align-items: flex-start;
}

.subs-sec .container.brand #premium,
.subs-sec .container.brand .subs-plan-sec ul.plan-info-detail {
    flex: 1;

}


.subs-sec {
    display: block;
    font-size: 14px;
    font-family: "open sans";
    text-align: center;
    color: #5d5d5d;
    width: 100%;
    border-radius: 5px;
    padding: 20px 0
}

.subs-sec ul {
    list-style: none;
    padding-left: 0
}

.subs-title-sec ul li {
    padding: 12px 5px 12px 0px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.subs-title-sec ul li.heading-row {
    border-bottom: none;
}

.subs-title-sec ul li.heading-row span {
    font-size: 14px;
    padding-bottom: 0px;
    border-bottom: 1px solid #5d5d5d;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
}

.subs-sec .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%
}

.subs-plan-sec {
    display: flex;
    flex-wrap: wrap;
    flex: 1
}

.subs-title-sec {
    flex: 0 0 280px;
    margin-right: 20px
}

#brand .subs-title-sec {
    flex: 0 0 310px
}

.subs-title-sec ul li {
    padding: 12px 5px 12px 0px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 13px;
}

.subs-title-sec ul li.subs-blank-col {
    min-height: 263px;
    border-bottom-color: transparent
}

.subs-title-sec ul li.subs-blank-col.active-plan {
    min-height: 285px
}

.subs-title-sec ul li.subs-blank-col.new-modified-plan {
    min-height: 300px
}

#brand .subs-title-sec ul li.subs-blank-col {
    min-height: 190px
}

.subs-sec.infl-mob.brand-mob .subs-title-sec ul li.subs-blank-col {
    min-height: 195px
}

.subs-title-sec ul li strong,
.subs-title-sec ul li span {
    display: block
}

.subs-title-sec ul li span {
    font-size: 12px
}

.subs-plan-sec ul {
    flex: 1;
    margin-right: 20px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 10px 10px 10px 10px;
    padding-left: 0;
    list-style: none;
}

.subs-plan-sec ul:last-of-type {
    margin-right: 0
}

.subs-plan-sec ul li {
    padding: 12px 15px;
    border-bottom: 1px solid #eee
}

.subs-plan-sec ul li.plan-title {
    background: #fff;
    text-align: center;
    padding: 0;
    display: block;
    color: #fff;
    font-size: 18px;
    border-radius: 10px 10px 0 0;
    min-height: 263px;
    color: #5d5d5d
}

.subs-sec.compare-infl .subs-plan-sec ul li.plan-title,
.subs-sec.compare-infl .subs-title-sec ul li.subs-blank-col {
    min-height: 120px
}

#influencer .subs-plan-sec ul li.plan-title,
#brand .subs-plan-sec ul li.plan-title {
    flex-direction: column;
    display: flex;
    justify-content: space-between
}

#influencer .subs-plan-sec ul li.plan-title .btn,
#brand .subs-plan-sec ul li.plan-title .btn {
    align-self: center
}

.subs-plan-sec ul li.plan-title strong {
    display: block;
    padding-bottom: 10px;
    background: #5d5d5d;
    color: #fff;
    padding-top: 10px;
    font-size: 19px;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
    margin-bottom: 20px
}

.subs-plan-sec ul li span {
    font-size: 14px;
    display: block
}

.subs-plan-sec ul li .plan-price {
    font-size: 26px;
    font-weight: normal;
    display: block;
    padding-bottom: 10px;
    line-height: 23px
}

.subs-plan-sec ul li .plan-price span {
    display: inline-block;
    font-size: 18px
}

.subs-plan-sec ul li .plan-price .plan-regular-price {
    font-size: 26px;
    padding-right: 10px;
    text-decoration: line-through
}

.subs-plan-sec ul li .plan-price.free-plan {
    margin-top: 17px;
    margin-bottom: 0px
}

.subs-plan-sec ul li.plan-title .plan-type {
    display: flex;
    background: transparent;
    border-radius: 0;
    font-size: 14px;
    margin-right: 0;
    box-shadow: none;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center
}

.subs-plan-sec ul li.plan-title .plan-type li {
    border-bottom: transparent;
    box-shadow: none;
    padding: 0;
    font-size: 15px;
    border-right: 1px solid #5d5d5d;
    border-radius: 5px 0 0 5px
}

.subs-plan-sec ul li.plan-title .plan-type li a {
    display: block;
    padding: 0px 13px !important;
    text-decoration: none;
    color: #5d5d5d;
    background-color: transparent
}

.subs-plan-sec ul li.plan-title .plan-type li a.active {
    color: #00aae5
}

.subs-plan-sec ul li.plan-title .plan-type li:last-of-type {
    border-right: none
}

.subs-plan-sec ul li.plan-title .plan-type li:last-of-type {
    border-radius: 0px 5px 5px 0
}

.subs-plan-sec ul li.plan-title .btn {
    margin-bottom: 20px
}

.subs-plan-sec ul li.plan-title .billing-type {
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: normal
}

.subs-plan-sec ul li.plan-title .billing-type span {
    font-size: 12px;
    display: inline-block;
    padding-left: 3px
}

.subs-plan-sec ul li.plan-title .billing-type.free {
    padding-bottom: 31px
}

#inf-premium,
.subs-sec #premium {
    flex: 0 0 100%
}

.text-left {
    text-align: left !important;
}