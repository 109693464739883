$color-primary: #00aae5 !default;

.spinner-container{
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    top: 0px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .8);
    flex-direction: column;
    z-index: 20000;

    .spinner-grow{
        height: 150px;
        width: 150px;
        position: relative;
    }

    .sr-only{
        font-size: xx-large;
        font-weight: bold;
        color: #0d6efd
    }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $color-primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.3s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.3s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.3s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.3s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
