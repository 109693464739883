.brand-dashboard-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .icon {
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }
}

.dashboard-insight {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .MuiGrid-root {
    @media only screen and (max-width:575px) {
      --Grid-columnSpacing: 6px;
      --Grid-rowSpacing: 0px;
      margin-left: 0;
      margin-right: 0
    }
  }

  .MuiGrid-item {

    @media only screen and (max-width:992px) {
      max-width: 33.333%;
      flex: 0 0 33.333%;

    }

    @media only screen and (max-width:575px) {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 0 !important;
    }

  }

  &-card {
    flex: 0 0 calc(20% - 12px);
    border: 1px solid #dee2e6;
    padding: 10px 20px;
    // margin-right: 15px;
    // margin-bottom: 15px;
    border-radius: 4px;
    height: 100px;
    background-color: #fff;
    font-size: 16px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-child(5n) {
      margin-right: 0;
    }

    span {
      &:first-of-type {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.camp-duration-data {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ag-format-container {
  width: 100%;
  // margin: 0 auto;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px -22px;

  padding: 0px 0 20px;
}

.ag-courses_item {
  flex-basis: calc(25% - 20px);

  margin: 0 10px 20px;

  overflow: hidden;

  // border: 1px solid black;

  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #ffffff;
  text-decoration: none;

  overflow: hidden;

  position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 18px;
  color: #000000;

  z-index: 2;
  position: relative;
}

.ag-courses_item:hover {
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
}

.ag-courses-item_link:hover .ag-courses-item_title {
  color: #f9f8f8;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    flex-basis: calc(50% - 20px);
  }

  // .ag-courses-item_title {
  //   font-size: 24px;
  // }
}

// @media only screen and (max-width: 767px) {
//   .ag-format-container {
//     width: 96%;
//   }
// }

@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;


  }

  // .ag-courses-item_link {
  //   padding: 22px 40px;
  // }

  .ag-courses-item_date-box {
    font-size: 16px;
  }
}