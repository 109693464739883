.custom-drawer {
  background: #fff;

  border: 1px solid $border-campaign-detail-card;
  border-radius: $border-radius__10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 500%);
  transform-origin: bottom center;
  z-index: -1;
  transition: all 0.35s cubic-bezier(0.65, 0, 0.35, 1);
  max-width: 500px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 100vh;
  overflow-y: auto;


  @media (width > 81.25em) {
    max-width: 36vw;
    max-height: 55vh;
  }

  &.active {
    z-index: 9999;
    transform: translate(-50%, -50%);
    transition: transform 0.35s cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 1;
    visibility: visible;

  }

  .custom-drawer-header {
    padding: 0.75rem 1.5rem 0.75rem 2rem;
    border-bottom: 1px solid $border-campaign-detail-card;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: $color-white;
    z-index: 1;
  }

  .custom-drawer-close {
    margin-left: auto;
  }

  .custom-drawer-title {
    font-weight: normal;
    padding-right: 16px;
  }

  .custom-drawer-body {
    padding: 0rem 2rem;
  }

  .custom-drawer-footer {
    padding: 1.5rem 2rem;
    position: sticky;
    bottom: 0;
    background: #fff;
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }

}





.custom-drawer-active {
  overflow: hidden;

  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;

  }
}