.badge {

    border-radius: 25px !important;
    display: flex;
    align-items: center;
    padding: 4px 20px !important;
    font-weight: 400;
    font-size: $font-size-sml !important;
    background-color: #E1E5EC;
    color: $color-body;

    &.badge-social,
    &.badge-share {
        width: 36px;
        height: 36px;
        border-radius: 50% !important;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            margin: auto;
            width: 20px;
            height: 20px;
        }
    }

    &.badge-share {
        background-color: $color-black;
        color: $color-white;
        border: 1px solid $color-black ;

        &:hover {
            background-color: $color-white;
            color: $color-black;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &.badge-secondary {
        background-color: $color-secondary;
        color: $color-white;
    }

    &.badge-primary-outline {
        color: $color-black;
        background-color: $color-white;
        border: 1px solid $color-black-7;

        svg[data-testid="YouTubeIcon"] {
            margin-right: 5px;
        }

    }
}