.image-div {

    position: relative;

    .favorate-icon {
        position: absolute;
        cursor: pointer;
        top: 285px;
        right: 10px;
    }
}