$color-primary: #00aae5 !default;


.inf-camp-detail {
  .camp-detail-card {
    &:last-of-type {
      border-bottom: none;
      margin-bottom: 10px;
      padding-bottom: 0;
    }
  }
}

.creative-w-sidebar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .sidebar {
    padding-left: 15px;
    border: none;
    border-right: 1px solid #dee2e6;
    background-color: transparent;
    flex: 0 0 230px;
    background-color: white;
    margin-left: -16px;
    // margin-right: 15px;

    .nav-tabs {
      border-bottom: none;

      li {
        .badge {
          font-size: 12px !important;
          padding: 3px 10px !important;
        }
      }

      .nav-link {
        border: none;
        color: $color-body;
        border-radius: 0;

        &.active {
          background: $color-primary !important;
          color: $color-white !important;


        }
      }
    }
  }

  .sidebar-content-sec {
    flex: 9999;
    // width: calc(100% - 260px);
    // padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    width: calc(100% - 260px);
    max-width: $container;
    margin: 0 auto;

  }

  .sidebar-content-tabs {
    margin-bottom: 0.94rem;

    .MuiTab-root {
      flex: 1 0 auto;
      margin: 0 0.63rem;

      .nav-link {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        flex: 0 0 auto;
      }
    }

    // li {
    //     display: inline-flex;

    //     a {

    //         color: $color-body;
    //         border-bottom: 1px solid transparent;
    //         margin-bottom: -1px;

    //         &.active,
    //         &:hover {
    //             color: $color-primary;
    //             border-bottom-color: $color-primary
    //         }

    //     }

    //     &:first-of-type {
    //         a {
    //             padding-left: 0;
    //         }
    //     }
    // }
  }

  &.campaign-proposal {
    .camp-detail-tab {
      border-bottom: 1px solid #ddd;

      .MuiTabs-indicator {
        background-color: #00aae5;
      }

      .nav.nav.sub-tabs {
        margin-bottom: 25px;
        min-height: 46px;
        margin-top: 10px;
        flex-wrap: wrap;

      }

      .nav-link {
        margin-bottom: 0px;
        margin-top: 1px;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .sidebar {
      width: 260px;
      flex: 0 0 260px;
    }


    .MuiTabs-scroller {
      .nav-link {


        .badge {
          background-color: transparent;
          // border: 1px solid rgba(0, 0, 0, 0.2);
        }

        &.active {
          .badge {
            // background-color: $color-primary;
            // color: $color-white ;
          }
        }

        &.active {
          // background-color: #00aae56e;

          #instagram path {
            color: $color-white;

          }

          // .camp-title {
          //     color: #2b2b2b;
          // }

        }

        // &:hover {
        //     color: #2b2b2b;
        //     // color: #00aae5;
        //     border-bottom: 1px solid $color-black;
        //     // background: #ddd;
        // }
      }
    }

    .plan-list {
      &.plan-list-less {

        .nav-link {
          flex: 0 0 auto;
        }

        .nav.sub-tabs {

          &:before,
          &:after {
            content: none;
          }
        }
      }

      .nav-link {
        flex: 0 0 auto;

        @include breakpoint(large) {
          flex: 0 0 20%;
        }
      }
    }

    .nav-link {
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      font-size: 0.875rem;
      text-transform: none;
      font-family: "Open Sans";
      font-weight: normal;
      min-height: 30px;
      max-width: inherit;
      flex: 1 0 auto;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        color: #f00;
      }
    }
  }
}

.camp-title {
  font-size: $font-size-sml;
  line-height: normal;
}

.title-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;


  @include breakpoint(medium) {

    align-items: center;
    flex-direction: row;

  }
}

.title-top .title-top-title {
  flex-direction: column;
  justify-content: end;
  width: 100%;

  @include breakpoint(small) {
    width: auto;
  }
}

.title-top .title-top-title h2 {
  max-width: 100%;

  line-height: 1.4;
  position: relative;
  padding-right: 30px;

  @include breakpoint(small) {
    // max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title-top h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0px;
  padding-bottom: 10px;

  @include breakpoint(medium) {

    padding-bottom: 0px;
    align-items: flex-end;

  }

}


.campaign-heading-tags {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    align-items: flex-end;

  }
}

.campaign-heading-tags-top {
  margin-bottom: 8px;
}

.campaign-heading-tags-btm {
  flex-wrap: wrap;
  display: flex;

  ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding-left: 0;

    li {
      margin-left: 5px;
    }
  }
}

.campaign-proposal {
  .right_section {}
}



.camp-inf-main-container {

  .main-container {
    margin-top: 20px;
  }

  .campaign-detail-head {
    display: flex;

    .campaign-info {
      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0px;
        padding: 0px;

        li {
          // border: 1px solid black;
          padding: 8px 10px;
          margin: 5px 10px;
          border-radius: 10px;
          background-color: lightgrey;
          cursor: pointer;

          &:last-child {
            background-color: $color-primary;
            color: white;
          }
        }
      }
    }

    .invited {
      background-color: deeppink !important;
      color: white;
    }
  }

  .left-side-nav {
    background-color: lightgray;
    padding: 0px;

    nav {
      display: flex;
      // flex-direction: column;

      .navlinks {
        text-decoration: none;
        padding: 10px 30px;
        color: black;
        font-size: large;
      }

      .active {
        color: $color-primary;
      }
    }

  }
}


// #shortDescription {
//   margin-bottom: 15px;
//   padding-bottom: 15px;
//   border-bottom: 1px solid;
// }

.product-detail-container {
  padding-bottom: 5px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;

  .product-detail-title-sec {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.product-detail-container {
  h5 {
    font-size: $font-size-large;
    margin-bottom: 4px;
  }

  h6 {
    font-size: $font-size-sml;
    margin-bottom: 10px;
  }
}


.plan-list-sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;

  .plan-list {
    width: calc(100% - 20%);

    &.plan-list-less {
      flex: 0 0 auto;
      max-width: 100%;
      width: auto;
    }
  }

  .plan-list-btn {
    flex: 0 0 auto;
    // padding: 12px 16px;
    text-decoration: none;
    font-size: $font-size-sml;
    // min-height: 54px;
    // border-bottom: 1px solid transparent;
    color: $color-body;
    text-align: center;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    svg {
      margin-bottom: 3px;
    }

    // &:hover {
    //   border-bottom-color: $color-black-7;
    //   color: $color-black;
    // }
  }

}

// New Campaign Detail page
.campaign-detail-tab {
  margin-bottom: 40px;
}

.camp-detail-card {
  padding: 0 20px 20px;
  border-bottom: 1px solid $border-campaign-detail-card;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ".";
  margin-bottom: 20px;

  @include breakpoint(small) {

    padding: 0 42px 25px;
    margin-bottom: 25px;
    grid-template-columns: 234px 1fr;
    grid-template-areas:
      ". .";
  }

  &.grid-1-col {

    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-template-areas:
      ".";
  }

  &.grid-2-row {
    grid-template-columns: 1fr;
    grid-template-areas:
      ".";
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

}

.camp-detail-card-left {
  margin-bottom: 0;

  @include breakpoint(small) {
    margin-right: 30px;
  }

  >strong {
    margin-bottom: 20px;
    display: block;
  }

}

.camp-detail-card-right>strong {
  margin-bottom: 10px;
  display: block;
}

.camp-detail-card-right {
  p {
    font-size: $font-size-sml;

    >br {
      display: none;
    }
  }
}

.camp-detail-card-sec-title {
  font-size: $font-size-large;
  display: block;
  margin-bottom: 10px;
}


.product-detail-info {
  .product-detail-img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
    border: 1px solid $border-creative-card;
    border-radius: $border-radius__10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius__10px;
    }
  }
}

.product-detail-price-info {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  font-size: $font-size-sml;

  span {
    &:first-of-type {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}

.camp-detail-card-inner {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint(small) {
    grid-template-columns: 234px 1fr;
  }

  &.grid-3-col {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 3.25rem 8.63rem;

    @media (width < 768px) {
      grid-gap: 1.25rem;
    }

    // @include breakpoint(xsml) {
    //   grid-template-columns: repeat(2, 1fr);
    // }

    // @include breakpoint(small) {
    //   grid-template-columns: repeat(3, 1fr);
    // }
  }
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.deliverable-list {
  ul {
    margin-left: 5px;
  }

  strong {
    margin-bottom: 5px;
    display: block;
  }

  li {
    font-size: $font-size-sml;
  }
}

.infl-det-list {
  display: flex;
  flex: 0 0 auto;
  padding-bottom: 10px;

  @include breakpoint(small) {
    flex: 0 0 33.33%;
    padding-bottom: 0px;
  }
}

.infl-det-list-sec {
  display: flex;
  flex-direction: column;

  @include breakpoint(small) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.proposal-dur-info {
    .infl-det-list-content {
      li {
        font-size: $font-size-xsml;
        color: $color-black-5d;
        font-weight: 600;
      }

      strong {
        font-size: $font-size-sml;
        font-weight: 400;
        color: $color-black;
      }

    }
  }
}

.infl-det-list-icon {
  margin-right: 5px;
  flex: 0 0 35px;
}

.infl-det-list-content {
  strong {
    color: #5d5d5d;
    font-weight: 600;
    font-size: $font-size-xsml;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      font-size: $font-size-sml;
    }
  }


}

// CAMPAIGN DETAIL TOGGLE
.camp-detail-toggle {
  li {}
}


.camp-detail-toggle {
  position: fixed;
  right: 0;
  background: #fff;
  z-index: 101;
  padding: 30px 20px 15px 20px;
  border-radius: 13px;
  transform: translate(100%, 90px);
  transition: transform 0.35s cubic-bezier(0.36, 0, 0.66, -0.56);
  top: 0;
  height: 100%;

  @include breakpoint(small) {
    padding: 30px 32px 15px 30px;
  }

  &.active {
    transform: translate(0%, 90px);
    transition: transform 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);

  }

  &-close {
    position: absolute;
    left: 5px;
    top: 15px;
    cursor: pointer;

    @include breakpoint(small) {
      min-width: 150px;
      left: 15px;
    }
  }

  >strong {
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
    font-size: $font-size-body;
  }

  &-active {
    position: relative;
    z-index: 100;
    overflow: hidden;

    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.28);
      z-index: 99;
    }
  }
}

.camp-detail-toggle ul {
  padding-left: 0;

  li {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 8px;

    font-size: $font-size-sml;

    span:first-of-type {
      min-width: 140px;

      @include breakpoint(small) {
        min-width: 150px;
      }

      flex: 1;
    }

    span:last-of-type {
      flex: 2;
    }
  }
}