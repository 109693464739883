$textColor: #5d5d5d;
$backgroundColor: #00aae5;

.profile_settings_container {
  height: calc(100vh - 100px);

  >.container {
    // height: calc(100vh - 120px);
    padding: 20px 0px;
    background-color: $color-white;
    border-radius: $border-radius-card;
    margin: 0 auto;
    width: calc(100% - 30px);
  }

  .profile_settings_main_container {
    padding: 0px;
    margin: 0px;
    height: calc(100% - 45px);

    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .profile_settings_profile {
      color: $textColor;
    }

    .profile_settings_left_side {
      border-right: 1px solid lightgray;
      padding: 0px;
      display: flex;
      flex: 0 0 240px;
      overflow-y: auto;
      // height: 100%;
    }

    .profile_settings_right_side {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
      padding-left: 25px;
      padding-right: 25px;

      .pin-pass-divider {
        .MuiDivider-wrapper {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.profile_settings_menu {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    // padding: 5px 20px;
    // padding: 5px 40px 5px 10px;
    font-size: $font-size-body;
    color: $textColor;
    cursor: pointer;
  }

  .profile_settings_navlinks {
    text-decoration: none;
    color: $textColor;
    padding: 8px 20px;
    display: inline-block;
    border-radius: 0px 20px 20px 0;
    // border-left: 2px solid transparent;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 0;
      width: 2px;
      background: transparent;
      transition: all 0.35s ease;
      bottom: 0;
    }


    &.active {
      &:before {
        height: 100%;
        background: #000;
      }
    }

    &:hover {
      &:before {
        height: 100%;
        background: #5d5d5d;
      }
    }
  }
}

.profile-menu-mobile {
  margin-bottom: 20px;

  button {
    font-family: $font-family-body !important;
    text-transform: none !important;
    letter-spacing: 0 !important;
  }
}

.add-photo-icon {
  position: absolute;
  bottom: 5px;
  right: 9px;
  padding: 5px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}