.submit-proposal-card {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-bottom: 1.875rem;
    border-bottom: 1px solid $border-campaign-detail-card;
    margin-bottom: 1.875rem;

    .submit-proposal-camp-title {
        font-size: $font-size-large;
    }

    h3 {
        font-size: $font-size--1_25rem;
    }

    .common-table-body tr td {
        border: none;

        hr {
            width: 100%;
        }
    }

    .MuiInputBase-multiline {
        border-radius: 0.5rem;
    }

}

.submit-proposal-initial {
    .submit-proposal-title {
        margin-bottom: 2rem;
        display: block;
    }

    &-detail {
        display: grid;
        grid-template-columns: auto;
        gap: 1.25rem;
        border-bottom: 1px solid $border-campaign-detail-card;
        padding-bottom: 1.875rem;

        // margin-bottom: 1.5rem;
        // padding-left: 1.875rem;
        // padding-right: 1.875rem;
        @include breakpoint(small) {

            grid-template-columns: 1fr 200px;
        }

        &__left__top {
            display: grid;
            grid-template-columns: 1fr auto;
        }

        &__left {
            display: grid;
            grid-gap: 0.5rem;


        }

        &__right {
            @include breakpoint(small) {
                border-left: 1px solid $border-campaign-detail-card;
                padding-left: 1.25rem;
            }
        }
    }

    .camp-description {
        margin-bottom: 1.5rem;
    }
}



.inf-proposal-main {


    .table-icon {
        // border: 1px solid black;
        margin-right: 10px;
        cursor: pointer;
    }

    .error-heading {
        min-height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .terms_conditions {
        display: flex;
        align-items: center;

        @media (width < 768px) {
            margin-bottom: 1rem;

        }
    }

    .total_amount {
        display: flex;
        align-items: center;
        margin-top: 10px;

        span {
            margin-right: 20px;
        }
    }

    .deliverables {
        span {
            margin-top: 20px;
            font-size: $font-size-large;

            &::after {
                display: none;
            }
        }
    }
}

.table-proposal-negotiation {
    table {
        border-collapse: separate;
    }

    .table-fixed-column {
        td {
            background-color: #fff;

            @include breakpoint(small) {

                &:first-of-type {
                    left: 0;
                    position: sticky;
                    z-index: 3;
                }

                &:nth-of-type(2) {
                    left: 175px;
                    position: sticky;
                    z-index: 3;
                }

                &:nth-of-type(3) {
                    left: 235px;
                    position: sticky;
                    z-index: 3;
                }

                &:nth-of-type(4) {
                    left: 345px;
                    z-index: 3;
                    position: sticky;
                }

            }
        }
    }

    thead {
        tr {
            @include breakpoint(small) {
                th:first-of-type {
                    left: 0;
                    position: sticky;
                    z-index: 3;
                }

                th:nth-of-type(2) {
                    left: 175px;
                    z-index: 3;
                    position: sticky;
                }

                &.table-proposal-negotiate-header-column {
                    th {
                        &:first-of-type {
                            left: 175px;
                            position: sticky;
                            z-index: 3;
                        }

                        &:nth-of-type(2) {
                            left: 235px;
                            position: sticky;
                            z-index: 3;
                        }

                        &:nth-of-type(3) {
                            left: 345px;
                            position: sticky;
                            z-index: 3;
                        }
                    }

                }
            }
        }
    }
}

.comment-section {
    ul {
        margin: 0px;
        padding: 0px 10px;
        list-style: none;

        li {
            border-bottom: 1px solid $border-color-card;
            padding: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
                border: none;
            }

            span {
                font-size: small;
                font-style: italic;
            }

        }
    }
}