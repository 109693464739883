.bag-container {

    padding: 10px 50px 10px 50px;

    .bag-title {
        display: flex;
        justify-content: center;

        .title {
            font-size: 24px;
            font-weight: 700;
            margin-top: 10px;
        }
    }

    .product-order {

        display: flex;
        margin-top: 20px;

        .bag-left {
            display: flex;
            flex-direction: column;
            width: 70%;
            padding: 10px;

            .bag-card {
                display: flex;
                margin-bottom: 70px;
            }

            .image {

                img {
                    width: 233px;
                    height: 233px;
                    border-radius: 10px;
                    border: 1px solid #d7d7d7;
                }
            }

            .context {

                width: 100%;
                padding: 0px 30px 0px 40px;

                .size-quantity {
                    display: flex;
                    margin-top: 20px;

                    .quantity {

                        .quantity-select {

                            .select {
                                border-radius: 10px;
                                width: 105px;
                                height: 40px;
                                margin-right: 20px;
                            }
                        }
                    }

                    .wishlist-button {
                        border: 1px solid #000000;
                        width: 187px;
                        height: 40px;
                        border-radius: 10px;
                        padding: 16px 14px 16px 14px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: 16px;
                            color: #000000;
                        }
                    }
                }

                .color {
                    margin-bottom: 0px;
                    font-size: 16px;
                }

                .title-value {
                    display: flex;
                    margin-top: 20px;

                    span {
                        font-size: 13px;
                        color: #000000;
                        line-height: 17.7px;
                        font-weight: 400;
                    }

                    .color-total {
                        display: flex;
                        flex-direction: column;
                        margin-right: 30px;
                    }

                    .value {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .product-name {
                    display: flex;
                    justify-content: space-between;
                    .delete-icon{
                        width: 30px;
                        height: 30px;
                        padding:4px 5px;
                        border-radius: 50%;
                        cursor: pointer;
                        &:hover{
                            background-color: rgb(238, 76, 76);
                        }
                    }
                    .name {
                        margin-bottom: -5px;
                        font-weight: 600;
                        font-size: 16px;
                        color: #000000;
                    }

                    .category {
                        color: #5d5d5d;
                        font-size: 16px;
                        font-weight: 400;
                        margin: 0px;
                    }
                }

                .product-price {

                    .price {

                        margin-bottom: -5px;


                        .old-price {
                            margin-right: 10px;
                            font-weight: 600;
                            color: #5d5d5d;
                            font-size: 20px;
                        }

                        .new-price {
                            margin-right: 10px;
                            font-weight: 600;
                            font-size: 20px;
                            color: #000000;
                        }

                        .discount {
                            color: #C13515;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }

                    .mrp {
                        font-size: 12px;
                        color: grey;
                    }

                }
            }

        }

        .bag-right {
            width: 30%;
            border-left: 2px solid lightgray;
            padding: 10px;

            .title {
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 600;
            }

            .order-value {

                border-bottom: 1px solid #000000;
                margin-bottom: 10px;

                .order {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                }
            }

            .total {
                font-size: 16px;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
            }

            .btn {
                width: 100%;
                margin-bottom: 30px;
                padding: 5px 26px 5px 26px;
                height: 40px;
                background-color: #000000;
                border-radius: 1px solid #000000;
                border-radius: 10px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
            }

            .return-policy{
                font-size: 13px;
                font-weight: 400;
                text-decoration: underline;
                color: #000000;
            }
        }
    }
}