// $activeColor: #00aae5;

.inf-campaign-card-container {
  .row {
    margin: 0;
  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .cardImage {
      width: 250px;
      height: auto;
      padding: 5px;
      border: 1px solid lightgray;
      border-radius: 10px;
    }
  }


  .campaign-duration {
    display: flex;
    flex-direction: column;

    span {
      margin: 5px 0px;
    }
  }

  .inf-details {
    display: flex;
    flex-direction: column;
    border-left: 1px solid black;
  }

  .inf-description {
    padding-left: 0;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-bottom: 0px;

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    p {
      >br {
        display: none;
      }
    }

    // .description {
    //     height: 250px;
    //     overflow-y: auto;
    // }
  }
}