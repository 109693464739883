.accordion {
    background: transparent !important;
    box-shadow: none !important;
    border: 1px solid $border-color-card;
    margin-bottom: 16px;
    border-radius: 8px !important;

    &.Mui-expanded {
        .accordion-title {
            background-color: #252525;
            color: $color-white;
            font-weight: 600;

            &.Mui-expanded {
                min-height: 48px !important;
                border-radius: 8px 8px 0 0;
            }

            .MuiAccordionSummary-content {
                margin: 12px 0;
            }

            .MuiAccordionSummary-expandIconWrapper {
                color: #fff;
            }
        }
    }

    &:before {
        height: 0 !important;
    }

    .accordion-title {
        padding: 0 16px;
    }

    .accordion-content {
        padding: 8px 16px 10px;
    }
}