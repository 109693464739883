$font-size-sml: 14px !default;
$border-color-card: #dee2e6 !default;
$border-radius-card: 14px !default;
$color-white: #ffffff !default;

.inf-creative-container {

    .creative_size {
        // max-height: 300px;
        // min-height: 200px;
        // margin: 20px;
        // cursor: pointer;
    }

    .creative-list {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;

            li {
                // border: 2px solid black;
                display: flex;

                .Rejected {
                    // border: 2px solid black;
                    display: flex;
                    margin-top: 20px;


                    font-weight: bold;
                }

                .Approved {
                    // display: flex;
                    // margin-top: 20px;
                    // padding: 5px 10px;
                    // border-radius: 5px;

                    // font-weight: bold;
                }

                .Pending {
                    // display: flex;
                    // margin-top: 20px;
                    // padding: 5px 10px;
                    // border-radius: 5px;

                    // font-weight: bold;
                }

            }
        }

        &.active {
            @include breakpoint(medium) {
                width: 60%;
            }
        }
    }

    .comments-section {
        width: 100%;

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;

            li {
                font-size: $font-size-sml;
                padding: 6px 0px;
                border-bottom: 1px solid lightgray;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-size: small;
                    font-style: italic;
                }

                &:last-child {
                    border: none;
                }

            }
        }
    }

    .selected-creative {
        max-height: 300px;
        min-height: 200px;
    }

    .selectedCreative {
        border: 1px solid $border-color-card;
        border-radius: $border-radius-card;
        padding: 10px 20px;
        position: absolute;
        right: 0px;
        top: 0%;
        transform: translateY(-50%);
        left: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        background-color: $color-white;

        @include breakpoint(small) {
            top: 50%;
            transform: translateY(-50%);
        }

        .send-comment {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .icon {
            cursor: pointer;
        }

    }


    .error {
        height: 70vh;
        // border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.overlay-creative {



        position: relative;

        &:after {
            position: fixed;
            content: "";
            top: 0;
            left: -15px;
            width: calc(100% + 30px);
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
            z-index: 9;
        }

        @include breakpoint(small) {
            position: static;
        }
    }
}