.address-card{
    border: 1px solid #d7d7d7;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    p{
        margin-bottom: 0px;
    }

    .select-btn{
        margin-top: 20px;
    }

    &:hover {
       cursor: pointer;
       box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
      }
}

.selected{
    border: 1px solid #d7d7d7;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);

    p{
        margin-bottom: 0px;
    }

    .select-btn{
        margin-top: 20px;
    }
}