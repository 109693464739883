.otp-fields {
    // margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;

    .otpcolumn {
        width: 42px;
        padding: 0px 5px !important;
        // margin-right: 5px !important;
        flex: 1;

        input {
            text-align: center;
            padding: 9.5px 14px;
        }

    }
}