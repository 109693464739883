.input-checkbox-box-container {

  .input-checkbox-box {
    position: relative;
    z-index: 0;
    padding: 1.25rem;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid $border-campaign-detail-card;
    flex: 1;
    border-radius: 4px;
    margin-left: 0;
    margin-right: 0;

    @media (width < 36rem) {
      // border: none;
      // border-bottom: 1px solid $border-campaign-detail-card;
    }

    .MuiCheckbox-root {
      position: static;

      &:after {
        content: "";
        background: transparent;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        top: 0;
        border-radius: 4px;
      }

      &.Mui-checked:after {
        background: #f2f2f2;
      }
    }

    &:first-of-type {
      border-left: 1px solid $border-campaign-detail-card;
      // border-radius: 4px 0 0 4px;

      // @media (width < 36rem) {
      //   // border-radius: 0;
      //   border-left: none;
      // }

      .MuiCheckbox-root {
        &.Mui-checked:after {
          // border-radius: 4px 0 0 4px;
        }
      }
    }

    &:last-of-type {
      // border-radius: 0 4px 4px 0;

      // @media (width < 36rem) {
      //   border-bottom: none;
      // }

      .MuiCheckbox-root {
        &.Mui-checked:after {
          // border-radius: 0 4px 4px 0;
        }
      }
    }
  }

}