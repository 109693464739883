$activeColor: #00aae5;

.inf-camp-detail {
    // padding: 0px 10px;
    border-radius: $border-radius__10px;

    .camp-cover-image {
        height: 182px;
        object-fit: contain;
        width: 182px;
        border: 1px solid $border-creative-card;
        padding: 0px;
        border-radius: $border-radius__10px;
    }

    .product-image {
        height: 200px;
    }

    .left-side {
        // margin-right: 20px;
    }

}


.card-row {
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-sml;
    width: $width-full;
    margin-bottom: 10px;

    span {
        margin-left: auto;
    }

    strong {
        padding-right: 10px;
    }
}

.product-description {
    font-size: $font-size-sml;
}